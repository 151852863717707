import React, { useContext, useState } from 'react';
import { DispatchContext, StateContext } from '../../../App';
import { filter, find, head } from 'lodash';
import { hasChanges } from '../../../utils';
import ConfirmBox from '../../../components/common/ConfirmBox';
import { modalOpenHandler } from '../../data/actions';
import { MODAL_TYPES } from '../../data/reducers';

export const EnvironmentDropDown = ({ onChangeEnvironment }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [confirmBoxShown, setConfirmBoxShown] = useState(false);
  const [open, setOpen] = useState(false);
  const [lastSelectedId, setLastSelectedId] = useState(undefined);
  const websites = state.websites;

  const currentWebsite = find(websites, (w) => w.id === state.currentWebsite);
  const handleChangeEnvironment = (websiteId) => {
    //check current site has unsaved changes
    const hasSiteChanges = hasChanges(state.lastSavedAsJson, state.history[state.history.length - 1]);
    if (hasSiteChanges) {
      setLastSelectedId(websiteId);
    } else {
      onChangeEnvironment(websiteId);
    }
  };

  const handleCreateDraft = () => {
    modalOpenHandler(dispatch, MODAL_TYPES.DRAFT_CREATE);
  };

  const onDeleteDraft = () => {
    alert('TODO');
  };

  const isProductionSelected = currentWebsite && currentWebsite.isProduction;

  const drafts = find(websites, (website) => website.isProduction !== true);
  const production = find(websites, (website) => website.isProduction === true);

  return (
    <div className='builder-flex builder-w-full builder-relative z-50 builder-h-full' onMouseLeave={() => setOpen(false)} style={{ zIndex: 999999999 }}>
      {confirmBoxShown && (
        <ConfirmBox
          title='Delete draft'
          text='Are you sure you want to delete this this draft?'
          type='DANGER'
          onCancel={() => {
            setConfirmBoxShown(false);
            setOpen(false);
          }}
          onConfirm={() => {
            onDeleteDraft();
            setConfirmBoxShown(false);
            setOpen(false);
          }}
        />
      )}

      <div className='builder-flex builder-items-center builder-justify-start builder-px-4 builder-py-2 builder-capitalize builder-w-full builder-cursor-pointer' onClick={() => setOpen(!open)}>
        {currentWebsite ? currentWebsite.name : 'Environment'} <i className='fal fa-angle-down ml-2' />
      </div>

      {open && (
        <div
          className='builder-absolute builder-border builder-border-gray-400 builder-bg-gray-100 builder-border-gray-200 builder-border builder-shadow-lg builder-p-4 builder-space-y-2'
          style={{ zIndex: 999999999, top: '100%', left: -1, right: -1 }}
        >
          {isProductionSelected && (
            <div className='builder-flex builder-flex-col'>
              {drafts && (
                <div>
                  <div className='builder-mb-1 builder-font-semibold'>Draft</div>
                  <div className='builder-flex builder-items-center builder-justify-between builder-capitalize'>
                    <div onClick={() => handleChangeEnvironment(drafts.id)} className='builder-cursor-pointer'>
                      {drafts.name}
                    </div>
                    {/*<i className="fal fa-trash-alt builder-ml-1 builder-cursor-pointer" style={{fontSize: 12}} onClick={() => setConfirmBoxShown(true)}/>*/}
                  </div>
                </div>
              )}
              {!drafts && (
                <div className='builder-cursor-pointer' onClick={() => handleCreateDraft()}>
                  <i className='fal fa-plus mr-2' />
                  Create new draft
                </div>
              )}
            </div>
          )}

          {!isProductionSelected && (
            <div className='builder-capitalize builder-cursor-pointer' onClick={() => handleChangeEnvironment(production.id)}>
              {production.name}
            </div>
          )}
        </div>
      )}

      {lastSelectedId && (
        <ConfirmBox
          text={`You have some unsaved changes, are you sure you want to switch environment'`}
          onCancel={() => setLastSelectedId(undefined)}
          onConfirm={() => {
            onChangeEnvironment(lastSelectedId);
            setLastSelectedId(undefined);
          }}
        />
      )}
    </div>
  );
};

export default EnvironmentDropDown;
