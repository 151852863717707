import React, { useContext, useState } from 'react';
import UseBuilderActions from '../../builder/useBuilderActions';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import { useMutation } from 'graphql-hooks';
import { useFileUpload } from '../../builder/useFileUpload';
import { v4 } from 'uuid';
import DiffEditorModal from './DiffEditorModal';
import { StateContext } from '../../../App';
import cogoToast from 'cogo-toast';

export const EXPORT_MUTATION = `
    mutation ExportWebsite {
        exportWebsite {  
            ok
            url
        }
    }`;

export const IMPORT_MUTATION = `
    mutation ImportWebsite($fileUrl: String!) {
        importWebsite(fileUrl: $fileUrl) {  
            ok
            configuration
        }
    }`;

export const UPDATE_CONFIGURATION = `
    mutation UpdateConfiguration($configuration: String!) {
        updateConfiguration(configuration: $configuration) {  
            ok            
        }
    }`;

let fileSelector;

const BackupAndRestore = ({ settings, isSuperUser, onChange, data }) => {
  const state = useContext(StateContext);
  const { executeScript, loading } = UseBuilderActions();
  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const [importMutation] = useMutation(IMPORT_MUTATION);
  const [updateConfigurationMutation] = useMutation(UPDATE_CONFIGURATION);
  const [exportFileUrl, setExportFileUrl] = useState(undefined);
  const [importFileUrl, setImportFileUrl] = useState(undefined);
  const [diffConfiguration, setDiffConfiguration] = useState(undefined);
  const [error, setError] = useState(undefined);
  const { uploadFile, loading: uploadLoading, error: uploadError } = useFileUpload();

  if (!isSuperUser) {
    return <div> not allowed </div>;
  }

  const handleBackup = () => {
    exportMutation().then((result) => {
      if (result.data && result.data.exportWebsite) {
        if (result.data.exportWebsite.ok) {
          window.open(result.data.exportWebsite.url, 'Download');
        } else {
          setError('Something went wrong..');
        }
      }
    });
  };

  const handleUploadAndRestore = (e) => {
    showFileSelector(e);
  };

  const handleRestore = (file) => {
    setImportFileUrl(file);

    importMutation({
      variables: {
        fileUrl: file
      }
    }).then((result) => {
      if (result.data && result.data.importWebsite) {
        if (result.data.importWebsite.ok) {
          const configuration = result.data.importWebsite.configuration;
          if (configuration) {
            setDiffConfiguration(JSON.parse(configuration));
            cogoToast.success('Restore complete!');
          }
        } else {
          setError('Something went wrong..');
        }
      }
    });
  };

  const handleUpdateConfiguration = (configuration) => {
    updateConfigurationMutation({
      variables: {
        configuration: configuration
      }
    }).then((result) => {
      if (result.data && result.data.updateConfiguration) {
        if (result.data.updateConfiguration.ok) {
          setDiffConfiguration(undefined);
          window.location.reload();
        } else {
          setError('Something went wrong..');
        }
      }
    });
  };

  const handleClose = () => {
    setDiffConfiguration(undefined);
    window.location.reload();
  };

  const showFileSelector = (e) => {
    e.preventDefault();

    fileSelector = document.createElement('input');
    fileSelector.setAttribute('id', v4());
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');

    fileSelector.addEventListener('change', (e) => {
      uploadFile(e.target.files[0], (url) => handleRestore(url));
    });

    fileSelector.setAttribute('accept', '.zip');
    fileSelector.click();
  };

  return (
    <div>
      <div className='flex flex-col'>
        <div className='builder-w-1/5 mb-4'>
          <ButtonWithIndicator loading={loading} onClick={handleBackup} text='Backup to file' />
        </div>
        {exportFileUrl && <div>{exportFileUrl} </div>}

        <div className='builder-w-1/5 mb-4'>
          <ButtonWithIndicator loading={loading} onClick={handleUploadAndRestore} text='Restore from file' />
        </div>
        {importFileUrl && <div>Restoring.... this could take a wile</div>}

        {error && <div className='text-red-500'>{error}</div>}
      </div>

      <DiffEditorModal
        visible={diffConfiguration !== undefined}
        leftCode={diffConfiguration}
        leftTitle='Configuration from import'
        rightCode={state.configuration}
        rightTitle='Current configuration'
        onApply={handleUpdateConfiguration}
        onClose={handleClose}
      />
    </div>
  );
};

export default BackupAndRestore;
