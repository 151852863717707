import React, { useContext, useEffect, useState } from 'react';
import { filter, find, map } from 'lodash';

import { DispatchContext, StateContext } from '../../App';
import { useLocation, useParams } from 'react-router-dom';
import { advertisementEditHandler, advertisementInitHandler, vehicleSearchFilterEditHandler, vehicleSearchFilterInitHandler } from '../data/actions';
import { v4 } from 'uuid';
import TextInput from '../builder/editors/components/TextInput';
import FiltersModal from '../builder/editors/FiltersModal';

const filterTypes = ['category', 'segment'];
const INITIAL_FILTER_DATA = {
  id: v4(),
  name: { nl: '', fr: '', en: '', de: '' },
  filters: [],
  type: filterTypes[0],
  disabled: false,
  isDraft: false
};

const Detail = ({ context }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const currentState = state.history[state.history.length - 1];
  const currentWebsite = find(state.websites, (w) => w.id === state.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;
  const { reference } = useParams();
  const location = useLocation();

  const [filterLanguage, setFilterLanguage] = useState(location?.state?.language ? location.state.language : context.culture);
  const current_filter = find(currentState.vehicleSearchFilters, (filter) => filter.id === reference);

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [editorRef, setEditorRef] = useState();
  const [editorFocused, setEditorFocused] = useState(false);

  useEffect(() => {
    if (filterLanguage && reference) {
      vehicleSearchFilterInitHandler(dispatch, {
        ...INITIAL_FILTER_DATA,
        id: reference,
        isDraft: !isCurrentSiteProduction
      });
    }
  }, [filterLanguage, reference]);

  const handleChangeName = (value) => {
    const data = {
      ...current_filter,
      name: {
        ...current_filter.name,
        [filterLanguage]: value
      }
    };
    vehicleSearchFilterEditHandler(dispatch, data);
  };

  const handleChangeFilters = (filters) => {
    const data = {
      ...current_filter,
      filters: filters
    };
    vehicleSearchFilterEditHandler(dispatch, data);
  };

  const handleChangeType = (type) => {
    const data = {
      ...current_filter,
      type: type
    };
    vehicleSearchFilterEditHandler(dispatch, data);
  };

  if (!current_filter || !currentState) {
    return null;
  }

  return (
    <div className='builder-flex builder-p-5 builder-w-full h-full builder-bg-gray-50'>
      <div className='builder-w-3/4 builder-mr-5'>
        <div className='builder-flex builder-flex-col builder-mb-5'>
          <div className='builder-flex builder-flex-col builder-w-200 builder-mb-5 builder-bg-white builder-p-5 builder-shadow-md'>
            <div className='builder-flex builder-mb-4'>
              <span className='builder-text-md builder-text-black builder-font-bold'>Language:</span>
              <span className='builder-uppercase builder-text-black builder-font-bold builder-ml-2 text-builder-md'>{filterLanguage}</span>
            </div>
            <div className='builder-flex'>
              <span className='builder-text-md builder-text-black builder-font-bold'>Translations: </span>
              <div className='builder-flex builder-space-x-2 builder-ml-2'>
                {map(
                  filter(currentState.languages, (l) => l.code !== filterLanguage && l.available),
                  (language) => {
                    return (
                      <div key={language.code} className='builder-flex builder-items-center builder-uppercase builder-cursor-pointer text-builder-md' onClick={() => setFilterLanguage(language.code)}>
                        {language.code} <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>

          <div className='builder-flex builder-mb-5 builder-bg-white'>
            <TextInput
              placeHolder='Add description'
              className='builder-flex builder-flex-1 builder-border builder-gray-300 builder-w-full builder-rounded-md'
              value={current_filter.name[filterLanguage]}
              onChanged={(value) => handleChangeName(value)}
            />
          </div>

          <div
            className='builder-flex builder-mb-5 builder-bg-white'
            onChange={(e) => {
              handleChangeType(e.target.value);
            }}
          >
            {map(filterTypes, (type) => {
              return (
                <React.Fragment>
                  <input type='radio' value={type} name='type' /> {type}
                </React.Fragment>
              );
            })}
          </div>

          <div className='font-bold' onClick={() => setFiltersModalOpen(true)}>{`Edit criteria (${current_filter.filters.length})`}</div>

          <FiltersModal
            isOpen={filtersModalOpen}
            filters={current_filter.filters ? current_filter.filters : []}
            onClose={(filters) => {
              handleChangeFilters([...filters]);
              setFiltersModalOpen(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Detail;
