import React, { useContext, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import { useSignOut } from '../../authentication/useSignout';
import { useClientMessages } from '../useClientMessages';
import TextArea from '../../builder/editors/components/TextArea';
import { sessionRestrictionModalViewSetHandler } from '../../data/actions';
import { SessionRestrictionModalViews } from '../SessionRestrictionModal';
import { DispatchContext, StateContext } from '../../../App';

const TakeOverRequest = () => {
  const [declined, setDeclined] = useState(false);
  const [declineMessage, setDeclineMessage] = useState("My work isn't finished yet");
  const { signOut } = useSignOut();
  const { sendTakeOverRequestAccepted, sendTakeOverRequestDeclined } = useClientMessages();
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);

  const handleLeave = (automatic = false) => {
    sendTakeOverRequestAccepted();
    signOut();
  };

  const handleContinueWorking = () => {
    sendTakeOverRequestDeclined(declineMessage);
    sessionRestrictionModalViewSetHandler(dispatch, undefined);
  };

  if (declined) {
    return (
      <div className='builder-text-black'>
        <h3>Send response message to other user</h3>

        <div className='builder-flex builder-justify-center builder-mb-6' style={{ fontSize: 16 }}>
          <TextArea value={declineMessage} className='builder-mt-2' placeHolder='Give an optional explanation' onChanged={(value) => setDeclineMessage(value)} />
        </div>

        <div className='builder-flex builder-justify-center builder-mt-6'>
          <ButtonWithIndicator loading={false} onClick={handleContinueWorking} text='Send response' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' className='builder-mr-4' />
        </div>
      </div>
    );
  }

  return (
    <div className='builder-text-black'>
      <h3>Another user is logged in and wants to edit the website</h3>

      <div className='builder-py-8 builder-flex builder-flex-col builder-justify-center builder-text-center'>
        <p>You have 2 minutes time to respond. </p>
        <p>After 2 minutes of inactivity, you'll be signed out and all you unsaved changes will we lost </p>
        <div />
      </div>

      <div className='builder-flex builder-justify-center builder-mb-6' style={{ fontSize: 16 }}>
        <CountdownCircleTimer
          isPlaying
          duration={120}
          colors={[
            ['#004777', 0.33],
            ['#F7B801', 0.33],
            ['#A30000', 0.33]
          ]}
          size={120}
          onComplete={() => handleLeave(true)}
        >
          {({ remainingTime }) => {
            return (
              <div className='builder-flex builder-flex-col builder-items-center'>
                {/*<div className="time">{Math.ceil(remainingTime / 60)}</div>*/}
                {/*<div>minutes</div>*/}
                <div className='time'>{Math.ceil(remainingTime)}</div>
                <div>seconds</div>
              </div>
            );
          }}
        </CountdownCircleTimer>
      </div>

      <div className='builder-flex builder-justify-center builder-mt-6'>
        <ButtonWithIndicator loading={false} onClick={handleLeave} text='Leave builder' />

        <ButtonWithIndicator loading={false} onClick={() => setDeclined(true)} text='Continue working' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' className='builder-ml-4' />
      </div>
    </div>
  );
};

export default TakeOverRequest;
