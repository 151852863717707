import React, { useState, useEffect, useContext } from 'react';
import { filter, find, map, upperFirst, values } from 'lodash';
import CopyContentFrom from './CopyContentFrom';
import { definitionsForContentType, getContentForField, getHumanText } from '../../../utils';
import FieldEditor from './FieldEditor';
import Dropdown from '../../../components/common/Dropdown';
import Toggle from 'react-toggle';
import { StateContext } from '../../../App';

const BlockEditorCustom = ({ block, campaigns, context, onEdit, onCampaignChange, onHideForCampaignChange }) => {
  const state = useContext(StateContext);
  const currentWebsite = find(state.websites, (w) => w.id === state.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;
  const availableCampaigns = () => {
    if (isCurrentSiteProduction) {
      return filter(campaigns, (c) => !c.disabled && !c.isDraft);
    } else {
      return filter(campaigns, (c) => !c.disabled && c.isDraft);
    }
  };

  const customBlock = find(context.customBlocks, (b) => b.name === block.componentName);

  return (
    <React.Fragment>
      <div className='builder-p-4'>
        <div className='builder-flex builder-flex-col builder-mb-4'>
          <div className='builder-flex builder-flex-col  builder-mb-4'>
            <span className='builder-font-bold builder-text-lg'> {customBlock.description}</span>

            <div className='flex items-center'>
              <span className='builder-text-sm'>ref: b{block.id.split('-')[0]}</span>
            </div>
          </div>
        </div>

        <React.Fragment>
          {/*<div onClick={() => onEdit(block)}>EDIT</div>*/}
          <div className='builder mt-6'>Note: You can edit this block in the left side menu (Custom)</div>
        </React.Fragment>
      </div>

      {availableCampaigns().length > 0 && (
        <div className='builder-flex builder-flex-col builder-bg-gray-100 builder-py-5 builder-border-t builder-border-gray-300'>
          <div className='builder-font-bold mb-2 builder-text-black builder-px-5'>Campaign</div>

          <div className='builder-flex builder-flex-1 builder-px-5'>
            <Dropdown
              options={map(availableCampaigns(), (c) => ({
                key: c.id,
                value: c.name
              }))}
              value={block.campaignId}
              maxWidth='100%'
              placeHolder='Select a campaign'
              borderClassName='builder-border builder-border-gray-300 builder-rounded-md'
              selectClassName='builder-py-2 builder-rounded-md'
              renderItem={(item) => {
                return (
                  <div className='builder-flex builder-justify-between builder-p-2' onClick={() => onCampaignChange(block.id, item.key)}>
                    <span className='builder-text-sm'>{item.value}</span>
                  </div>
                );
              }}
            />
          </div>

          <div className='builder-flex builder-w-full builder-mb-4 builder-mt-4 builder-px-5'>
            <div className='builder-flex builder-flex-col builder-flex-1 builder-justify-center builder-ml-2'>Hide this component during campaign</div>
            <div className='builder-flex builder-flex-col builder-justify-center builder-items-end'>
              <Toggle
                defaultChecked={false}
                checked={block.hideForCampaign}
                onChange={(e) => {
                  onHideForCampaignChange(block.id, e.target.checked);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default BlockEditorCustom;
