import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import { filter, find, findKey, includes, keys, map } from 'lodash';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import { StateContext } from '../../../App';
import BodiesFilter from './components/filters/BodiesFilter';
import { FilterTypes } from '@digitalexperience/dealer-website-core';
import FuelFilter from './components/filters/FuelFilter';
import MakesFilter from './components/filters/MakesFilter';
import ModelsFilter from './components/filters/ModelsFilter';
import YearFilter from './components/filters/YearFilter';
import MileageFilter from './components/filters/MileageFilter';
import PowerFilter from './components/filters/PowerFilter';
import GearboxFilter from './components/filters/GearboxFilter';
import DoorsFilter from './components/filters/DoorsFilter';
import SeatsFilter from './components/filters/SeatsFilter';
import CategoryFilter from './components/filters/CategoryFilter';
import LabelFilter from './components/filters/LabelFilter';
import DealershipFilter from './components/filters/DealershipFilter';
import VatFilter from './components/filters/VatFilter';
import EquipmentFilter from './components/filters/EquipmentFilter';
import ExteriorColorFilter from './components/filters/ExteriorColorFilter';
import InteriorColorFilter from './components/filters/InteriorColorFilter';
import InteriorUpholsteryFilter from './components/filters/InteriorUpholsteryFilter';
import EmissionNormFilter from './components/filters/EmissionNormFilter';
import CO2Filter from './components/filters/CO2Filter';
import SoldFilter from './components/filters/SoldFilter';
import SearchFilter from './components/filters/SearchFilter';
import IntegerInput from './components/IntegerInput';
import Toggle from 'react-toggle';
import SortByFilter from './components/filters/SortByFilter';
import LimitFilter from './components/filters/LimitFilter';
import ShuffleFilter from './components/filters/ShuffleFilter';
import SimilarFilter from './components/filters/SimilarFilter';
import MakePriorityFilter from './components/filters/MakePriorityFilter';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible'
  },
  overlay: { zIndex: 999 },
  overflow: 'visible'
};

export const hasFilterWithValue = (filters, type, value) => {
  const filter = find(filters, (f) => f.type === type && f.value === value);
  return filter ? true : false;
};

const FiltersModal = ({ filters: filtersFromProps, isOpen, onClose, onCreate, context }) => {
  const state = useContext(StateContext);
  const [filters, setFilters] = useState(filtersFromProps);

  const addFilter = (type, value) => {
    let tmpFilters = [...filters, { type: type, value: value }];

    // Delete double make and make|model combinations
    if (type === FilterTypes.makeModel && value.indexOf('|') > -1) {
      tmpFilters = [...filter(tmpFilters, (f) => f.type !== FilterTypes.makeModel || (f.type === FilterTypes.makeModel && f.value !== value.split('|')[0]))];
    }

    setFilters(tmpFilters);
  };

  const deleteFilter = (type, value) => {
    if (type === FilterTypes.makeModel && value.indexOf('|') === -1) {
      // delete of make, delete also all models
      const toDelete = filter(filters, (f) => (f.type === FilterTypes.makeModel && f.value === value) || (f.type === FilterTypes.makeModel && f.value.indexOf('|') > -1 && f.value.split('|')[0] === value));
      const tmpFilters = filter(filters, (f) => !includes(toDelete, f));
      setFilters(tmpFilters);
    } else if (type && value) {
      setFilters(filter(filters, (f) => f.value !== value));
    } else {
      setFilters(filter(filters, (f) => f.type !== type));
    }
  };

  const updateFilter = (type, value) => {
    setFilters(map(filters, (f) => (f.type === type ? { type: type, value: value } : f)));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel='Filters modal'>
      <div>Define filters</div>
      <div className='builder-flex builder-justify-end builder-mb-4'>
        <div
          className='builder-cursor-pointer'
          onClick={() => {
            onClose(filters);
          }}
        >
          <i className='fal fa-times builder-text-xl' />
        </div>
      </div>

      <div className='builder-overflow-x-scroll' style={{ width: 500, maxHeight: 600 }}>
        <SearchFilter
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.search),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
          filterUpdate={updateFilter}
        />

        <MakesFilter
          filterData={map(state.dealerMakes, (i) => ({
            key: i.key,
            text: i.name,
            models: map(i.models, (m) => ({ key: m.key, text: m.name }))
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.makeModel),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <ModelsFilter
          filterData={map(state.dealerMakes, (i) => ({
            key: i.key,
            text: i.name,
            models: map(i.models, (m) => ({ key: m.key, text: m.name }))
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.makeModel),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <BodiesFilter
          filterData={map(state.bodyTypes, (i) => ({
            key: i.key,
            text: i.description
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.body),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <YearFilter
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.year),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterUpdate={updateFilter}
          filterDelete={deleteFilter}
        />

        <FuelFilter
          filterData={map(state.fuels, (i) => ({
            key: i.key,
            text: i.description
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.fuel),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <MileageFilter
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.mileage),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterUpdate={updateFilter}
          filterDelete={deleteFilter}
        />

        <PowerFilter filters={filter(filters, (f) => f.type === FilterTypes.pk || f.type === FilterTypes.kw)} filterAdd={addFilter} filterUpdate={updateFilter} filterDelete={deleteFilter} />

        <GearboxFilter
          filterData={map(state.gearboxes, (i) => ({
            key: i.key,
            text: i.description
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.transmission),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <DoorsFilter
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.doorsRange),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterUpdate={updateFilter}
          filterDelete={deleteFilter}
        />

        <SeatsFilter
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.seatsRange),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterUpdate={updateFilter}
          filterDelete={deleteFilter}
        />

        <CategoryFilter
          filterData={map(state.categories, (i) => ({
            key: i.key,
            text: i.description
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.category),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <LabelFilter
          filterData={map(state.labels, (i) => ({
            key: i.key,
            text: i.description
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.label),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <DealershipFilter
          filterData={map(state.dealerships, (i) => ({
            key: i.id,
            text: i.name
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.dealership),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <VatFilter
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.vat),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <SoldFilter
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.sold),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterUpdate={updateFilter}
          filterDelete={deleteFilter}
        />

        <EquipmentFilter
          filterData={map(state.equipments, (i) => ({
            key: i.key,
            text: i.description
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.equipments),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <ExteriorColorFilter
          filterData={map(state.exteriorColors, (i) => ({
            key: i.key,
            text: i.description,
            custom: i.colorCode
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.exteriorColor),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <InteriorColorFilter
          filterData={map(state.interiorColors, (i) => ({
            key: i.key,
            text: i.description,
            custom: i.colorCode
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.interiorColor),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <InteriorUpholsteryFilter
          filterData={map(state.interiorUpholsteries, (i) => ({
            key: i.key,
            text: i.description,
            custom: i.colorCode
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.interiorUpholstery),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <EmissionNormFilter
          filterData={map(state.emissionNorms, (i) => ({
            key: i.key,
            text: i.description
          }))}
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.euroNorm),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
        />

        <CO2Filter
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.co2Range),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterUpdate={updateFilter}
          filterDelete={deleteFilter}
        />

        <SortByFilter
          filterValues={map(
            filter(filters, (f) => f.type === FilterTypes.sortBy),
            (b) => b.value
          )}
          filterAdd={addFilter}
          filterDelete={deleteFilter}
          filterUpdate={updateFilter}
        />

        <div>
          <div className='mt-4 font-bold'>Custom Options</div>

          <LimitFilter
            filterValues={map(
              filter(filters, (f) => f.type === FilterTypes.limit),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
            filterUpdate={updateFilter}
          />

          <ShuffleFilter
            filterValues={map(
              filter(filters, (f) => f.type === FilterTypes.shuffle),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
            filterUpdate={updateFilter}
          />

          <SimilarFilter
            filterValues={map(
              filter(filters, (f) => f.type === FilterTypes.similar),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
            filterUpdate={updateFilter}
          />

          <MakePriorityFilter
            filterValues={map(
              filter(filters, (f) => f.type === FilterTypes.makePriority),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
            filterUpdate={updateFilter}
          />
        </div>
      </div>
    </Modal>
  );
};
export default FiltersModal;
