import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import TextInput from '../builder/editors/components/TextInput';
import Dropdown from '../../components/common/Dropdown';
import { filter, findKey, keys, map, orderBy } from 'lodash';
import ButtonWithIndicator from '../../components/common/ButtonWithIndicator';
import { getTranslationForPage } from '../../utils';
import { v4 } from 'uuid';
import { DefaultPageNames, PageTypes } from '../../constants';
import { useHistory } from 'react-router-dom';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '55%',
    height: 450
  },
  overlay: {
    zIndex: 999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const initialState = {
  type: PageTypes.custom,
  name: '',
  copyFrom: undefined
};

const resolveInitialState = (type) => {
  if (type === undefined) {
    return initialState;
  } else {
    return {
      ...initialState,
      type: type
    };
  }
};

const PageCreateModal = ({ type, isOpen, onClose, onCreate, context }) => {
  const [state, setState] = useState(resolveInitialState(type));
  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();

  const setValue = (key, value) => {
    setState({
      ...state,
      [key]: value
    });
  };

  useEffect(() => {
    if (isOpen) {
      setState(initialState);
      setFormErrors({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (type) {
      setState({ ...state, type: type });
    }
  }, [type]);

  const types = map(PageTypes, (key) => {
    return {
      value: DefaultPageNames[key],
      key: key
    };
  });

  const existingPages = map(context.pages, (page) => {
    return {
      value: getTranslationForPage(page, context.culture),
      key: page.id,
      type: page.type
    };
  });

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (state.type === PageTypes.custom && (state.name === '' || state.name.length < 3)) {
      errors['name'] = 'The draft name needs to be at least 3 chars long.';
      isValid = false;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const handleCreatePage = () => {
    if (isValid()) {
      if (state.type !== PageTypes.custom && state.type !== PageTypes.contactDetail && state.type !== PageTypes.blogPost) {
        onCreate(v4(), state.type, DefaultPageNames[state.type], state.copyFrom);
      } else if (state.type === PageTypes.blogPost) {
        onCreate(v4(), PageTypes.blogPost, `${state.type}-${v4()}`, state.copyFrom);
      } else {
        onCreate(v4(), state.type, state.name, state.copyFrom);
      }

      history.push('');
    }
  };

  const existingPagesToShow = type && filter(existingPages, (p) => p.type === type).length > 0 ? filter(existingPages, (p) => p.type === type) : existingPages;

  return (
    <Modal isOpen={isOpen} style={customStyles} appElement={document.getElementById('root') || undefined} contentLabel='Create page modal'>
      <div className='builder-flex builder-w-full builder-justify-end builder-mb-5'>
        <i className='fal fa-times builder-text-xl builder-cursor-pointer builder-text-2xl' onClick={() => onClose()} />
      </div>

      <div style={{ width: 500 }}>
        {type === undefined && (
          <React.Fragment>
            <div className='builder-text-black builder-font-bold builder-mb-2.5'>Select a page type</div>
            <Dropdown
              options={types}
              value={state.type}
              minWidth={500}
              placeHolder='Select a page type'
              borderClassName='builder-border builder-border-gray-300 builder-rounded-md'
              selectClassName='builder-p-2 builder-rounded-md'
              renderItem={(item) => {
                return (
                  <div className='builder-flex builder-justify-between builder-p-2' onClick={() => setValue('type', item.key)}>
                    <span className='builder-text-sm'>{item.value}</span>
                  </div>
                );
              }}
            />
          </React.Fragment>
        )}

        {state.type && (state.type === PageTypes.custom || state.type === PageTypes.contactDetail) && (
          <div className='builder mt-6'>
            <div className='builder-text-black builder-font-bold builder-mb-2.5'>Name</div>
            <TextInput placeHolder='name' value={state.name} className={`builder-border builder-rounded-md ${formErrors['name'] ? 'builder-border-red-300' : 'builder-border-gray-300'}`} onChanged={(value) => setValue('name', value)} />
            {formErrors['name'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['name']}</span>}
          </div>
        )}

        <div className='builder mt-6'>
          <div className='builder-text-black builder-font-bold builder-mb-2.5'>Create new page from existing page(optional)</div>
          <Dropdown
            options={orderBy(existingPagesToShow, ['value'])}
            value={state.copyFrom}
            minWidth={500}
            placeHolder='Select existing page'
            borderClassName='builder-border builder-border-gray-300 builder-rounded-md'
            selectClassName='builder-p-2 builder-rounded-md'
            renderItem={(item) => {
              return (
                <div className='builder-flex builder-justify-between builder-p-2' onClick={() => setValue('copyFrom', item.key)}>
                  <span className='builder-text-sm'>{item.value}</span>
                </div>
              );
            }}
          />
        </div>
      </div>

      <div className='builder-flex builder-justify-start builder-mt-6 builder-mb-11'>
        <ButtonWithIndicator loading={false} onClick={() => onClose()} className='builder-ml-0' text='Cancel' />

        <ButtonWithIndicator loading={false} onClick={() => handleCreatePage()} text='Create new page' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
      </div>
    </Modal>
  );
};
export default PageCreateModal;
