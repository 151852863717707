import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import TextInput from './components/TextInput';
import Dropdown from '../../../components/common/Dropdown';
import { filter, find, findKey, groupBy, keys, map, orderBy } from 'lodash';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import GenericSelect from '../../../components/common/GenericSelect';
import { DispatchContext, StateContext } from '../../../App';
import DraftJsEditor from './components/DraftJsEditor';
import { seoPageContentCreateHandler, seoPageContentUpdateHandler } from '../../data/actions';
import { mapArrayToString } from '../../../utils';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '60%'
  },
  overlay: {
    zIndex: 999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const initialFilters = {
  make: null,
  model: null,
  category: null,
  fuel: null,
  transmission: null
};

const PageContentModal = ({ isOpen, onClose, onCreate, context }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const currentState = state.history[state.history.length - 1];
  const content = filter(currentState.pageContentSEO, (c) => c.language === context.culture);

  const [currentItem, setCurrentItem] = useState(undefined);
  const [mode, setMode] = useState('edit');

  const [filters, setFilters] = useState(initialFilters);
  const [text, setText] = useState('');

  const setFilterValue = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleChangeItem = (text) => {
    seoPageContentUpdateHandler(dispatch, currentItem.key, currentItem.language, text);
  };

  const handleEditItem = (item) => {
    setMode('edit');
    setCurrentItem(item);
  };

  const handleNew = () => {
    setMode('new');
    setFilters(initialFilters);
    setText('');

    const filters = {
      make: null,
      model: null,
      category: null,
      fuel: null,
      transmission: null
    };
    //       seoPageContentCreateHandler(dispatch, "blaap", context.culture, 'stock', filters, null)
  };

  const handleCreateNew = () => {
    const keys = [];
    if (filters.make) keys.push(filters.make);
    if (filters.model) keys.push(filters.model);
    if (filters.category) keys.push(filters.category);
    if (filters.fuel) keys.push(filters.fuel);
    if (filters.transmission) keys.push(filters.transmission);

    const key = mapArrayToString(
      keys.map((el) => '/' + el),
      ''
    );
    seoPageContentCreateHandler(dispatch, key, context.culture, 'stock', filters, text);
  };

  const isEditorDisabled = !((mode === 'edit' && currentItem !== undefined) || mode === 'new');

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} appElement={document.getElementById('root') || undefined} contentLabel='Page content modal'>
      <div className='builder-flex builder-w-full builder-justify-end builder-mb-5'>
        <i className='fal fa-times builder-text-xl builder-cursor-pointer builder-text-2xl' onClick={() => onClose()} />
      </div>

      <div className='builder-flex builder-w-full'>
        <div className='builder-flex builder-w-full'>
          <div className='builder-flex builder-flex-col builder-w-1/3 builder-mr-4'>
            <div className='builder-flex builder-w-full builder-justify-start builder-mb-3'>
              <ButtonWithIndicator loading={false} onClick={() => handleNew()} text='Add new' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' className='builder-ml-0' />
            </div>

            {/*<div className="builder-cursor-pointer hover:builder-underline builder-mb-4" onClick={handleNew}>Add new</div>*/}
            <div className='builder-flex builder-flex-col builder-w-full' style={{ maxHeight: 550 }}>
              {content.length === 0 && <div className='builder-flex builder-flex-1 builder-items-center builder-justify-center builder-w-full builder-h-full builder-text-gray-400 builder-text-sm'>You will see filters here.</div>}
              <div className='builder-h-full builder-overflow-y-scroll'>
                {content.length > 0 &&
                  map(orderBy(content, ['key']), (c, index) => {
                    const isSelected = mode === 'edit' && currentItem && currentItem.key === c.key;
                    const isLast = index === content.length - 1;
                    return (
                      <div
                        key={`${c.key}-${index}`}
                        className={`builder-cursor-pointer builder-w-full builder-truncate builder-align-top builder-self-start hover:bg-gray-100 builder-py-1 builder-px-2 ${isLast ? '' : 'builder-border-b builder-border-gray-100'} ${
                          isSelected ? 'bg-gray-100' : ''
                        }`}
                        onClick={() => handleEditItem(c)}
                      >
                        {c.key}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className='builder-w-2/3'>
            {mode === 'new' && (
              <div className='builder-mb-4'>
                <div className='builder-flex'>
                  <div className='builder-w-1/2 builder-mr-4'>
                    <div>Make</div>
                    <GenericSelect
                      placeHolder='Make'
                      items={map(state.dealerMakes, (i) => ({
                        key: i.key,
                        text: i.name,
                        models: map(i.models, (m) => ({
                          key: m.key,
                          text: m.name
                        }))
                      }))}
                      values={[filters.make]}
                      handleFilterChanged={(item) => setFilterValue('make', item.value)}
                    />
                  </div>

                  <div className='builder-w-1/2'>
                    <div>Model</div>
                    <GenericSelect
                      placeHolder='Model'
                      items={map(find(state.dealerMakes, (make) => make.key === filters.make)?.models, (i) => ({
                        key: i.key,
                        text: i.name
                      }))}
                      allOption={{ key: 'model', text: 'All models' }}
                      values={[filters.model]}
                      handleFilterChanged={(item) => setFilterValue('model', item.value)}
                    />
                  </div>
                </div>

                <div className='builder-flex'>
                  <div className='builder-w-1/2 builder-mr-4'>
                    <div>Category</div>
                    <GenericSelect
                      placeHolder='Category'
                      items={map(state.categories, (i) => ({
                        key: i.key,
                        text: i.description
                      }))}
                      values={[filters.category]}
                      handleFilterChanged={(item) => setFilterValue('category', item.value)}
                    />
                  </div>

                  <div className='builder-w-1/2'>
                    <div>Fuel</div>
                    <GenericSelect
                      placeHolder='Fuel'
                      items={map(state.fuels, (i) => ({
                        key: i.key,
                        text: i.description
                      }))}
                      values={[filters.fuel]}
                      handleFilterChanged={(item) => setFilterValue('fuel', item.value)}
                    />
                  </div>
                </div>

                <div className='builder-flex'>
                  <div className='builder-w-1/2 builder-mr-4'>
                    <div>Transmission</div>
                    <GenericSelect
                      placeHolder='Transmission'
                      items={map(state.gearboxes, (i) => ({
                        key: i.key,
                        text: i.description
                      }))}
                      values={[filters.transmission]}
                      handleFilterChanged={(item) => setFilterValue('transmission', item.value)}
                    />
                  </div>
                </div>
              </div>
            )}

            {currentItem && (
              <div className='builder-mb-4'>
                Chosen filter: <span className='builder-font-semibold'>{currentItem.key}</span>
              </div>
            )}

            <div className=''>
              <DraftJsEditor
                value={mode === 'new' ? text : currentItem?.text ? currentItem.text : ''}
                readOnly={isEditorDisabled}
                className={`builder-border builder-border-gray-300 ${isEditorDisabled ? 'bg-gray-100' : 'bg-white'}`}
                onChange={(value) => {
                  if (mode === 'new') {
                    setText(value);
                  } else {
                    if (currentItem !== undefined) {
                      handleChangeItem(value);
                    }
                  }
                }}
                updateValueOnChange={true}
              />
            </div>
          </div>
        </div>
      </div>

      {mode === 'new' && (
        <div className='builder-flex builder-justify-end builder-mt-6'>
          <ButtonWithIndicator loading={false} onClick={() => onClose()} text='Cancel' />

          <ButtonWithIndicator loading={false} onClick={() => handleCreateNew()} text='Save' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
        </div>
      )}
    </Modal>
  );
};

export default PageContentModal;
