import React, { useState } from 'react';
import BlogOverviewProperties from './BlogOverviewProperties';
import { filter, find } from 'lodash';
import { PageTypes } from '../../../constants';

const hasPropertiesOfType = (type, properties) => {
  return filter(properties, (p) => p.type === type).length > 0;
};

const PageProperties = ({ page, properties, onChange, context }) => {
  const handleChangeBlogOverviewProperties = (data) => {
    onChange('blog-overview', data);
  };

  // Currently 1 type supported (blog-overview)
  if (page.type === PageTypes.blogPost) {
    const propertiesOfType = find(properties, (p) => p.type === 'blog-overview');
    return <BlogOverviewProperties data={propertiesOfType ? propertiesOfType.data : undefined} onChange={handleChangeBlogOverviewProperties} context={context} />;
  }

  return null;
};

export default PageProperties;
