import React from 'react';
import { isImage } from '../../../utils';
import { baseFileSelector } from '../../builder/baseFileSelector';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';

const FileUploader = ({ url, handleFileUpload, handleSelectExistingFile, loading, error }) => {
  return (
    <div>
      <ButtonWithIndicator loading={false} onClick={handleSelectExistingFile} icon='fas fa-images' text='Check existing' colorClass='builder-bg-white builder-text-primary' borderClass='builder-border builder-border-bg-primary' />

      <ButtonWithIndicator loading={false} onClick={handleFileUpload} icon='fal fa-plus' text='Add new' colorClass='builder-bg-white builder-text-primary' borderClass='builder-border builder-border-bg-primary' />

      {url && url !== '' && (
        <React.Fragment>
          <div>File url:</div>
          <div>{url}</div>

          {url && url !== '' && isImage(url) && <img src={url} style={{ maxWidth: 266, maxHeight: 200 }} alt={url} />}
        </React.Fragment>
      )}
    </div>
  );
};
export default baseFileSelector(FileUploader, 'image');
