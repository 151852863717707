import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import ButtonWithIndicator from '../../components/common/ButtonWithIndicator';
import { useSignOut } from '../authentication/useSignout';
import { useClientMessages } from './useClientMessages';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import ToManyUsers from './take-over-request/ToManyUsers';
import TakeOverRequest from './take-over-request/TakeOverRequest';
import TakeOverRequestWaiting from './take-over-request/TakeOverRequestWaiting';
import TakeOverRequestAccepted from './take-over-request/TakeOverRequestAccepted';
import TakeOverRequestDeclined from './take-over-request/TakeOverRequestDeclined';
import ForceSignOut from './take-over-request/ForceSignOut';
import ForceSignOutWaiting from './take-over-request/ForceSignOutWaiting';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '33%'
  },
  overlay: {
    zIndex: 999,
    background: '#00000080 0% 0% no-repeat'
  }
};

export const SessionRestrictionModalViews = {
  RestrictedByToManyUsers: 'RestrictedByToManyUsers',
  TakeOverRequest: 'TakeOverRequest',
  TakeOverRequestWaitingResponse: 'TakeOverRequestWaitingResponse',
  TakeOverRequestAccepted: 'TakeOverRequestAccepted',
  TakeOverRequestDeclined: 'TakeOverRequestDeclined',
  ForceSignOut: 'ForceSignOut',
  ForceSignOutWaiting: 'ForceSignOutWaiting'
};

const SessionRestrictionModal = ({ isOpen, view, onReload, context }) => {
  const renderView = (view) => {
    switch (view) {
      case SessionRestrictionModalViews.RestrictedByToManyUsers:
        return <ToManyUsers />;
      case SessionRestrictionModalViews.TakeOverRequest:
        return <TakeOverRequest />;
      case SessionRestrictionModalViews.TakeOverRequestWaitingResponse:
        return <TakeOverRequestWaiting onReload={onReload} />;
      case SessionRestrictionModalViews.TakeOverRequestAccepted:
        return <TakeOverRequestAccepted onReload={onReload} />;
      case SessionRestrictionModalViews.TakeOverRequestDeclined:
        return <TakeOverRequestDeclined />;
      case SessionRestrictionModalViews.ForceSignOut:
        return <ForceSignOut />;
      case SessionRestrictionModalViews.ForceSignOutWaiting:
        return <ForceSignOutWaiting onReload={onReload} />;
      default:
        return <div>Not Implemented Yet</div>;
    }
  };

  return (
    <div>
      <Modal isOpen={view !== undefined} style={customStyles} contentLabel='Session Restriction Modal' appElement={document.getElementById('root') || undefined}>
        {renderView(view)}
      </Modal>
    </div>
  );
};

export default SessionRestrictionModal;
