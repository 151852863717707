import React from 'react';
import { includes } from 'lodash';
import { FilterTypes } from '@digitalexperience/dealer-website-core';

const DoorsFilter = ({ filterValues, filterAdd, filterUpdate, filterDelete }) => {
  const doorsRangeValue = filterValues && filterValues.length > 0 ? filterValues[0] : undefined;

  const filterExists = (range) => {
    return doorsRangeValue ? includes(doorsRangeValue, range[0]) && includes(doorsRangeValue, range[1]) : false;
  };

  const handleFilterChanged = (range) => {
    if (filterValues.length > 0) {
      filterUpdate(FilterTypes.doorsRange, range);
    } else {
      filterAdd(FilterTypes.doorsRange, range);
    }
  };

  const handleRemoveFilter = () => {
    filterDelete(FilterTypes.doorsRange);
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Nr. of doors</div>
      <div className='builder-grid builder-grid-cols-4 builder-center' style={{ minHeight: 44 }}>
        <div
          className={`builder-filter-input builder-cursor-pointer builder-text-center builder-justify-center builder-border-t builder-border-l builder-border-b builder-rounded-l-4 builder-flex builder-items-center ${
            !doorsRangeValue ? 'bg-gray-200' : 'builder-text-black'
          }`}
          onClick={() => handleRemoveFilter()}
        >
          All
        </div>
        <div
          className={`builder-filter-input builder-cursor-pointer builder-text-center builder-border-t builder-border-b builder-flex builder-items-center builder-justify-center ${filterExists([2, 3]) ? 'bg-gray-200' : 'builder-text-black'}`}
          onClick={() => handleFilterChanged([2, 3])}
        >
          2/3
        </div>
        <div
          className={`builder-filter-input builder-cursor-pointer builder-text-center builder-border-t builder-border-b border-l builder-flex builder-items-center builder-justify-center  ${
            filterExists([4, 5]) ? 'bg-gray-200' : 'builder-text-black'
          }`}
          onClick={() => handleFilterChanged([4, 5])}
        >
          4/5
        </div>
        <div
          className={`builder-filter-input builder-cursor-pointer builder-text-center builder-border builder-rounded-r-4 builder-flex builder-items-center builder-justify-center ${filterExists([6, 7]) ? 'bg-gray-200' : 'builder-text-black'}`}
          onClick={() => handleFilterChanged([6, 7])}
        >
          6/7
        </div>
      </div>
    </React.Fragment>
  );
};

export default DoorsFilter;
