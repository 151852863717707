import React from 'react';
import { FilterTypes } from '@digitalexperience/dealer-website-core';
import IntegerInput from '../IntegerInput';

const LimitFilter = ({ filterValues, filterAdd, filterUpdate }) => {
  const value = filterValues && filterValues.length > 0 ? filterValues[0] : 0;

  const handleChange = (value) => {
    if (filterValues.length === 0) {
      filterAdd(FilterTypes.limit, value);
    } else {
      filterUpdate(FilterTypes.limit, value);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Limit</div>
      <div className='builder-mb-2'>Max vehicles to show</div>

      <div className='builder-text-sm builder-font-medium  builder-mb-2'>
        <IntegerInput value={value} placeHolder='10' onChanged={(value) => handleChange(value)} />
      </div>
    </React.Fragment>
  );
};

export default LimitFilter;
