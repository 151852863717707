import React, { useContext, useEffect } from 'react';
import { useManualQuery, useQuery } from 'graphql-hooks';
import moment from 'moment';
import { StateContext } from '../App';

const HISTORY = `
    query History {
        history {
           lastUpdate, 
            lastPublish
        }
       }`;

const History = () => {
  const [fetchHistory, { loading, error, data }] = useManualQuery(HISTORY);
  const state = useContext(StateContext);

  useEffect(() => {
    fetchHistory();
  }, [state.lastAction]);

  useEffect(() => {
    fetchHistory();
  }, []);

  if (!data) {
    return null;
  }

  const lastUpdate = data.history.lastUpdate ? moment(data.history.lastUpdate) : undefined;
  const lastPublish = data.history.lastPublish ? moment(data.history.lastPublish) : undefined;

  return (
    <div>
      <div>last save: {lastUpdate ? lastUpdate.format('DD/MM/YYYY HH:mm:ss') : '/'}</div>
      <div>last publish: {lastPublish ? lastPublish.format('DD/MM/YYYY HH:mm:ss') : '/'}</div>
    </div>
  );
};

export default History;
