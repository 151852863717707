import React, { useContext, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useSignOut } from '../../authentication/useSignout';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import { StateContext } from '../../../App';

const TakeOverRequestDeclined = () => {
  const { signOut } = useSignOut();
  const state = useContext(StateContext);

  return (
    <div className='builder-text-black'>
      <h3 className='builder-text-center'>Take over declined</h3>

      <div className='builder-py-8 builder-flex builder-flex-col builder-justify-center builder-text-center'>
        <p>Other user rejected your request. Please try again later. </p>
      </div>

      <div className='font-bold'>Message from user:</div>
      <div>{state.sessionTakeOverMessage.payload?.message}</div>

      <div className='builder-flex builder-justify-center builder-mt-6'>
        <ButtonWithIndicator loading={false} onClick={() => signOut()} className='builder-ml-0' text='Try again later' />
      </div>
    </div>
  );
};

export default TakeOverRequestDeclined;
