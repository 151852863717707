import React, { isValidElement, useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Menu from './components/Menu';
import { useHistory } from 'react-router-dom';
import { routes } from '../data/constants';
import { useMutation, useQuery } from 'graphql-hooks';
import { SettingsQuery, SettingsUpdateMutation } from '../data/graphql';
import { filter, forEach, head, includes, isEqual, map, omit, orderBy, reduce } from 'lodash';
import cogoToast from 'cogo-toast';
import { StateContext } from '../../App';
import HeaderMenu from './components/HeaderMenu';

import Page404 from '../../components/Page404';
import { LoadingIndicator, ErrorIndicator } from '../../components/common';
import ConfirmBox from '../../components/common/ConfirmBox';
import GeneralSettings from './general/GeneralSettings';
import Seo from './general/Seo';
import Analytics from './general/Analytics';
import Integrations from './general/Integrations';
import ConfigurationEditor from './general/ConfigurationEditor';
import FileUploader from './general/FileUploader';
import Release from './general/Release';
import BackupAndRestore from './general/BackupAndRestore';

const mapSettings = (settings) => {
  return reduce(
    settings,
    (result, value, key) => {
      let newValue = value;
      if (value === 'True' || value === 'true') newValue = true;
      if (value === 'False' || value === 'false') newValue = false;
      return { ...result, [key]: newValue };
    },
    {}
  );
};

const unmapSettings = (settings) => {
  return reduce(
    settings,
    (result, value, key) => {
      let newValue = value;
      if (value === true) newValue = 'true';
      if (value === false) newValue = 'false';
      return { ...result, [key]: newValue };
    },
    {}
  );
};

const DynamicComponent = (props) => {
  if (isValidElement(props.component)) {
    return React.cloneElement(props.component, omit(props, 'component'));
  }

  return <div>The component has not been created yet.</div>;
};

const generalSettings = [
  {
    title: 'Configuration JSON',
    mainComponent: <ConfigurationEditor />,
    default: true,
    reference: 'configuration',
    icon: 'far fa-globe-asia',
    position: 1
  },
  {
    title: 'File uploader',
    mainComponent: <FileUploader />,
    default: true,
    reference: 'file-uploader',
    icon: 'far fa-globe-asia',
    position: 2
  },
  {
    title: 'Release',
    mainComponent: <Release />,
    default: true,
    reference: 'release',
    icon: 'far fa-globe-asia',
    position: 3
  },
  {
    title: 'Backup and Restore',
    mainComponent: <BackupAndRestore />,
    default: true,
    reference: 'backup_and_restore',
    icon: 'far fa-globe-asia',
    position: 4
  }
];

const General = () => {
  const [activeComponent, setActiveComponent] = useState(generalSettings.find((setting) => setting.default).reference);
  const [settings, setSettings] = useState({});
  const [initSettings, setInitSettings] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const state = useContext(StateContext);
  const isSuperUser = state.session.user.isSuperUser;
  const currentState = state.history[state.history.length - 1];
  let history = useHistory();
  const [saveMutation, { loading: saving }] = useMutation(SettingsUpdateMutation);
  const { loading, error, data } = useQuery(SettingsQuery);

  useEffect(() => {
    if (!loading) {
      setSettings(mapSettings(data.settings));
      setInitSettings({
        settings: mapSettings(data.settings)
      });
    }
  }, [loading, data]);

  const checkLanguagesChanged = (oldConfig, newConfig) => {
    // Check if new languages added; if true --> copy all blocks for new language (without content!)
    //checkLanguagesChanged(initSettings.settings.configuration, settings.configuration);//

    if (oldConfig === newConfig) return;

    const oldLanguages = JSON.parse(oldConfig).website.languages;
    const newLanguages = JSON.parse(newConfig).website.languages;

    if (oldLanguages !== newLanguages) {
      const addedLanguages = filter(newLanguages, (l) => !includes(oldLanguages, l));

      if (addedLanguages) {
        const sourceLanguage = oldLanguages[0];
        forEach(addedLanguages, (l) => {
          copyContentHandler(dispatch, sourceLanguage, l);
          cogoToast.success(`PageBlocks created for ${l}!`);
        });
      }
    }
  };

  const handleGeneralSettingsSave = () => {
    saveMutation({
      variables: {
        data: {
          settings: unmapSettings(settings)
        }
      }
    })
      .then((res) => {
        const { ok } = res.data.settingsUpdate;
        if (ok) {
          cogoToast.success('Settings saved!');

          setInitSettings({
            settings: settings
          });
        }
      })
      .catch((error) => {
        cogoToast.error('Error saving settings!');
      });
  };

  const hasChanges = () => {
    let settingsHaveChanges = !isEqual(settings, initSettings.settings);
    let languagesHaveChanges = !isEqual(currentState.languages, initSettings.languages);
    return settingsHaveChanges || languagesHaveChanges;
  };

  const handleBack = () => {
    if (!hasChanges()) {
      history.push(routes.HOME);
    } else {
      setShowConfirm(true);
    }
  };

  const handleCancel = () => {
    history.push(routes.HOME);
  };

  if (loading && !error) {
    return <LoadingIndicator />;
  }

  if (!loading && error) {
    return <ErrorIndicator error='Settings could not be fetched.' />;
  }

  if (!loading && !error) {
    const setting = generalSettings.find((setting) => setting.reference === activeComponent);
    if (!setting) {
      return <Page404 />;
    }

    if (setting) {
      const { mainComponent } = setting;
      return (
        <div className='builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm'>
          {showConfirm && (
            <ConfirmBox
              onCancel={() => setShowConfirm(false)}
              onConfirm={() => {
                history.push(routes.HOME);
              }}
            />
          )}
          <Header />

          <Menu title='General settings' onSave={handleGeneralSettingsSave} onBack={handleBack} onCancel={handleCancel} loading={saving} saveDisabled={!hasChanges()} />

          <div className='builder-flex builder-items-center builder-justify-center builder-bg-white'>
            <div className='builder-container'>
              <div className='builder-flex builder-items-center builder-justify-center builder-py-2'>
                <div className='builder-flex builder-container builder-h-full'>
                  <div className='builder-w-1/5 builder-border-r builder-border-gray-200'>
                    {orderBy(generalSettings, ['position'], ['asc']).map((item, i) => {
                      return (
                        <div key={`${item.reference}-${i}`} className={`builder-p-3 builder-cursor-pointer hover:bg-gray-200 ${activeComponent === item.reference ? 'builder-bg-gray-100' : ''}`} onClick={() => setActiveComponent(item.reference)}>
                          <i className={`mr-2 ${item.icon}`} /> {item.title}
                        </div>
                      );
                    })}
                  </div>
                  <div className='builder-w-4/5 builder-mx-4'>
                    <div className='builder-bg-white '>
                      <DynamicComponent component={mainComponent} data={data} settings={settings} isSuperUser={isSuperUser} onChange={(updatedSettings) => setSettings(updatedSettings)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};

export default General;
