import React, { useEffect, useState } from 'react';
import { filter, find, head } from 'lodash';
import Fade from 'react-reveal/Fade';
import { FilterTypes } from '@digitalexperience/dealer-website-core';
import SelectBox from '../../../../../components/common/SelectBox';

const labels = {
  PK: 'PK',
  KW: 'KW'
};

const initPK = { min: '', max: '' };
const initKW = { min: '', max: '' };

const PowerFilter = ({ filters, filterAdd, filterUpdate, filterDeleteByType, hasFilter }) => {
  const [activeOption, setActiveOption] = useState(labels.PK);
  const [pk, setPk] = useState(initPK);
  const [kw, setKw] = useState(initKW);

  useEffect(() => {
    const pkFilters = head(filter(filters, (f) => f.type === FilterTypes.pk));
    const kwFilters = head(filter(filters, (f) => f.type === FilterTypes.kw));

    if (pkFilters) {
      setPk({ min: pkFilters.value[0], max: pkFilters.value[1] });
    }

    if (kwFilters) {
      setKw({ min: kwFilters.value[0], max: kwFilters.value[1] });
    }
  }, []);

  const powerOptions = [
    {
      value: labels.PK,
      label: 'hp'
    },
    {
      value: labels.KW,
      label: 'kW'
    }
  ];

  const onPkChange = () => {
    if (pk.min !== '' || pk.max !== '') {
      const value = [pk.min, pk.max];

      const hasPkFilter = filter(filters, (f) => f.type === FilterTypes.pk).length > 0;
      if (hasPkFilter) {
        filterUpdate(FilterTypes.pk, value);
      } else {
        filterAdd(FilterTypes.pk, value);
      }
    } else {
      filterDeleteByType(FilterTypes.pk);
    }
  };

  const onKwChange = () => {
    if (kw.min !== '' || kw.max !== '') {
      const value = [kw.min, kw.max];

      const hasKwFilter = filter(filters, (f) => f.type === FilterTypes.kw).length > 0;
      if (hasKwFilter) {
        filterUpdate(FilterTypes.kw, value);
      } else {
        filterAdd(FilterTypes.kw, value);
      }
    } else {
      filterDeleteByType(FilterTypes.kw);
    }
  };

  const renderPK = () => {
    return (
      <React.Fragment>
        <input
          className='builder-w-1/2 builder-filter-input builder-border builder-rounded-l-4 focus:builder-outline-none'
          value={pk.min}
          style={{ maxHeight: 46 }}
          placeholder='From'
          onChange={(e) => setPk({ ...pk, min: e.target.value })}
          onBlur={() => onPkChange()}
        />
        <input
          className='builder-w-1/2 builder-filter-input builder-border-t builder-border-b builder-border-r builder-rounded-r-4 focus:builder-outline-none'
          value={pk.max}
          style={{ maxHeight: 46 }}
          placeholder='To'
          onChange={(e) => setPk({ ...pk, max: e.target.value })}
          onBlur={() => onPkChange()}
        />
      </React.Fragment>
    );
  };

  const renderKW = () => {
    return (
      <React.Fragment>
        <input
          className='builder-w-1/2 builder-filter-input builder-border-l-2 builder-border-t-2 builder-border-b-2 builder-border-r-2 builder-rounded-l-4 focus:builder-outline-none '
          value={kw.min}
          style={{ maxHeight: 46 }}
          placeholder='From'
          onChange={(e) => setKw({ ...kw, min: e.target.value })}
          onBlur={() => onKwChange()}
        />
        <input
          className='builder-w-1/2 builder-filter-input builder-border-r-2 builder-border-t-2 builder-border-b-2 builder-rounded-r-4 focus:builder-outline-none'
          value={kw.max}
          style={{ maxHeight: 46 }}
          placeholder='To'
          onChange={(e) => setKw({ ...kw, max: e.target.value })}
          onBlur={() => onKwChange()}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Power</div>
      <div className='builder-flex'>
        <div className='builder-w-2/5 builder-mr-2'>
          <SelectBox value={find(powerOptions, (i) => i.value === activeOption)} options={powerOptions} className='focus:builder-outline-none' isLoading={false} onChange={(option) => setActiveOption(option.value)} />
        </div>

        <Fade spy={activeOption}>
          <div className='builder-w-3/5 builder-w-full builder-flex'>
            {activeOption === labels.PK && renderPK()}
            {activeOption === labels.KW && renderKW()}
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default PowerFilter;
