import React from 'react';
import { FilterTypes } from '@digitalexperience/dealer-website-core';
import GenericList from '../../../../../components/common/GenericList';
import { includes } from 'lodash';

const CategoryFilter = ({ filterData, filterValues, filterAdd, filterDelete, hasFilterWithValue }) => {
  const handleChange = (data) => {
    if (includes(filterValues, data.value)) {
      filterDelete(FilterTypes.category, data.value);
    } else {
      filterAdd(FilterTypes.category, data.value);
    }
  };
  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Vehicle condition</div>
      <GenericList items={filterData} values={filterValues} handleFilterChanged={handleChange} />
    </React.Fragment>
  );
};

export default CategoryFilter;
