import React, { useContext } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import { useSignOut } from '../../authentication/useSignout';
import { useClientMessages } from '../useClientMessages';
import { sessionRestrictionModalViewSetHandler } from '../../data/actions';
import { DispatchContext } from '../../../App';
import { SessionRestrictionModalViews } from '../SessionRestrictionModal';

const ToManyUsers = () => {
  const { signOut } = useSignOut();
  const { sendTakeOverRequest } = useClientMessages();
  const dispatch = useContext(DispatchContext);

  const handleTakeOverRequest = () => {
    const message = sendTakeOverRequest();
    sessionRestrictionModalViewSetHandler(dispatch, SessionRestrictionModalViews.TakeOverRequestWaitingResponse);
  };

  return (
    <div className='builder-text-black'>
      <h3 className='builder-text-center'>Whoops..</h3>

      <div className='builder-py-8 builder-flex builder-flex-col builder-justify-center builder-text-center'>
        <p> Another user is working on the website in.</p>
        <p> Only one user can modify the website at the same time</p>
      </div>

      <div className='builder-flex builder-justify-center builder-mt-6'>
        <ButtonWithIndicator loading={false} onClick={() => signOut()} className='builder-ml-0' text='Try again later' />

        <ButtonWithIndicator loading={false} onClick={handleTakeOverRequest} text='Request take over' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
      </div>
    </div>
  );
};

export default ToManyUsers;
