import React from 'react';
import { FilterTypes } from '@digitalexperience/dealer-website-core';
import Toggle from 'react-toggle';

const MakePriorityFilter = ({ filterValues, filterAdd, filterUpdate }) => {
  const value = filterValues && filterValues.length > 0 ? filterValues[0] : false;

  const handleChange = (value) => {
    if (filterValues.length === 0) {
      filterAdd(FilterTypes.makePriority, value);
    } else {
      filterUpdate(FilterTypes.makePriority, value);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Make Priority</div>
      <div className='builder-mb-2'>Show vehicles in specific make order (based on make priority)</div>

      <div className='builder-text-sm builder-font-medium  builder-mb-2'>
        <Toggle defaultChecked={value} aria-label='No label tag' onChange={(e) => handleChange(e.target.checked)} />
      </div>
    </React.Fragment>
  );
};

export default MakePriorityFilter;
