import React, { useContext, useState } from 'react';
import { filter, find, map, orderBy, values } from 'lodash';
import ConfirmBox from '../../components/common/ConfirmBox';
import { campaignDeleteHandler, campaignSelectHandler, modalOpenHandler } from '../data/actions';
import { MODAL_TYPES } from '../data/reducers';
import { DispatchContext, StateContext } from '../../App';
import moment from 'moment';

const Overview = ({ context, onChangeEnvironment }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const currentState = state.history[state.history.length - 1];
  const [campaignToDelete, setCampaignToDelete] = useState('');
  const currentWebsite = find(state.websites, (w) => w.id === state.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;

  const campaigns = currentState.campaigns;

  const formatToYearMonthDay = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const today = formatToYearMonthDay(new Date());

  const mappedCampaigns = orderBy(
    map(values(campaigns), (campaign) => {
      const start = formatToYearMonthDay(campaign.start);
      const stop = formatToYearMonthDay(campaign.stop);

      let todayStartDiff = moment(campaign.start).diff(today, 'days');

      return {
        ...campaign,
        name: campaign.name,
        key: campaign.id,
        start: moment(start).format('LL'),
        stop: moment(stop).format('LL'),
        isExpired: today > stop,
        isActive: today >= start && today <= stop,
        isInFuture: todayStartDiff > 0,
        activeInDays: todayStartDiff
      };
    }),
    ['start'],
    ['asc']
  );

  const onDeleteCampaign = (item) => {
    campaignDeleteHandler(dispatch, item.key);
  };

  const onCreateCampaign = () => {
    modalOpenHandler(dispatch, MODAL_TYPES.CAMPAIGN_CREATE);
  };

  const onEditCampaign = (item) => {
    campaignSelectHandler(dispatch, item.key);
    modalOpenHandler(dispatch, MODAL_TYPES.CAMPAIGN_CREATE);
  };

  const statusLabel = ({ isExpired, isActive, isInFuture, activeInDays }) => {
    if (isExpired) {
      return <div className='builder-bg-red-100 builder-rounded-md builder-text-red-900 builder-text-white builder-px-2 builder-py-1 builder-w-2/3'>Expired</div>;
    } else if (isActive) {
      return <div className='builder-bg-green-100 builder-rounded-md builder-text-green-900 builder-px-2 builder-py-1 builder-w-2/3'>Active</div>;
    } else if (isInFuture) {
      return <div className='builder-bg-gray-100 builder-rounded-md builder-text-gray-700 builder-px-2 builder-py-1 builder-w-2/3'>{`starts in ${activeInDays} ${activeInDays === 1 ? 'day' : 'days'}`}</div>;
    } else {
      return '';
    }
  };

  return (
    <div className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
      {campaignToDelete !== '' && (
        <ConfirmBox
          title='Delete campaign'
          text='Are you sure you want to delete this campaign?'
          type='DANGER'
          onCancel={() => setCampaignToDelete('')}
          onConfirm={() => {
            onDeleteCampaign(campaignToDelete);
            setCampaignToDelete('');
          }}
        />
      )}

      <div className='builder-flex builder-mb-5'>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Campaigns</div>
        <div className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100' onClick={() => onCreateCampaign()}>
          <i className='fal fa-plus builder-mr-2' />
          Add new
        </div>
      </div>

      {mappedCampaigns.length === 0 && (
        <div className='builder-rounded-md builder-bg-blue-50 builder-p-4'>
          <div className='builder-flex'>
            <div className='builder-flex-shrink-0'>
              <i className='fal fa-info-circle builder-text-blue-600' />
            </div>
            <div className='builder-ml-3 builder-flex-1 md:builder-flex md:builder-justify-between'>
              <p className='builder-text-sm builder-text-blue-600'>No campaigns available for {isCurrentSiteProduction ? 'production' : 'drafts'}.</p>
            </div>
          </div>
        </div>
      )}

      {mappedCampaigns.length > 0 && (
        <div className='builder-flex builder-flex-col builder-w-full'>
          <div className='builder-overflow-x-auto'>
            <div className='builder-py-2 builder-align-middle builder-inline-block builder-min-w-full'>
              <div className='builder-shadow builder-overflow-hidden builder-border-b builder-border-gray-200'>
                <table className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
                  <thead className='builder-bg-gray-50'>
                    <tr>
                      <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '40%' }}>
                        Name
                      </th>
                      <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
                        Start
                      </th>
                      <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
                        Stop
                      </th>
                      <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
                        Status
                      </th>
                      <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                    {map(mappedCampaigns, (campaign) => {
                      const canEdit = !campaign.isDraft && !isCurrentSiteProduction ? false : true; // Only edit drafts in draft environment, (production posts not editable in draft)
                      return (
                        <tr key={campaign.key}>
                          <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-font-bold builder-text-black' style={{ width: '40%' }}>
                            {campaign.name}
                          </td>
                          <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '15%' }}>
                            {campaign.start}
                          </td>
                          <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '15%' }}>
                            {campaign.stop}
                          </td>
                          <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '15%' }}>
                            {statusLabel(campaign)}
                          </td>
                          <td className='builder-flex builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '15%' }}>
                            {canEdit && (
                              <React.Fragment>
                                <div className='builder-flex builder-items-center builder-cursor-pointer builder-border-r builder-border-gray-300 builder-mr-3 builder-pr-3' onClick={() => onEditCampaign(campaign)}>
                                  Edit <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                                <div className='builder-flex builder-items-center builder-cursor-pointer ' onClick={() => setCampaignToDelete(campaign)}>
                                  Delete <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                              </React.Fragment>
                            )}
                            {!canEdit && (
                              <React.Fragment>
                                <span className='builder-font-bold'>No actions allowed in draft environment</span>
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview;
