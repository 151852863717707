import React, { useContext, useState } from 'react';
import UseBuilderActions from '../../builder/useBuilderActions';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';

const Release = ({ settings, isSuperUser, onChange, data }) => {
  const { executeScript, loading } = UseBuilderActions();

  if (!isSuperUser) {
    return <div> not allowed </div>;
  }

  return (
    <div>
      <div className='flex flex-col'>
        <div className='builder-w-1/5 mb-4'>
          <ButtonWithIndicator loading={loading} onClick={() => executeScript('update', 'all')} text='update customer' />
        </div>

        <div className='builder-w-1/5 mb-4'>
          <ButtonWithIndicator loading={loading} onClick={() => executeScript('release', 'website')} text='Release website' />
        </div>

        <div className='builder-w-1/5 mb-4'>
          <ButtonWithIndicator loading={loading} onClick={() => executeScript('release', 'cms')} text='Release cms' />
        </div>

        <div className='builder-w-1/5 mb-4'>
          <ButtonWithIndicator loading={loading} onClick={() => executeScript('release', 'builder')} text='Release builder' />
        </div>

        <div className='builder-w-1/5 mb-4'>
          <ButtonWithIndicator loading={loading} onClick={() => executeScript('release', 'all')} text='Release all' />
        </div>
      </div>
    </div>
  );
};

export default Release;
