import { keys, map } from 'lodash';

export const DefaultPageNames = {
  home: 'index',
  vehicles: 'stock',
  vehicleDetail: 'vehicle',
  vehicleTakeOver: 'takeover',
  vehicleCompare: 'vehicle-compare',
  vehicleSearchAgent: 'search-agent',
  vehicleReservation: 'reservation',
  search: 'search',
  contact: 'contact',
  contactDetail: 'contact/dealer',
  services: 'services',
  about: 'about',
  privacy: 'privacy',
  blog: 'blog',
  blogPost: 'blog-post',
  favorites: 'favorites',
  login: 'login',
  registration: 'registration',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  financingInformation: 'financing-information',
  account: 'account',
  accountOverview: 'account-overview',
  accountProfile: 'account-profile',
  accountChangePassword: 'account-change-password',
  accountCarAgent: 'account-car-agent',
  accountFavorites: 'account-favorites',
  custom: 'custom',
  404: '404'
};

export const PageTypes = keys(DefaultPageNames).reduce((a, v) => ({ ...a, [v]: v }), {});
