import React, { useContext, useState } from 'react';
import { filter, find, groupBy, keys, map } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ConfirmBox from '../../components/common/ConfirmBox';
import { DispatchContext, StateContext } from '../../App';
import { routes } from '../data/constants';
import { advertisementDeleteHandler, pageSelectHandler } from '../data/actions';
import { v4 } from 'uuid';

const Overview = ({ context, currentLanguage, setCurrentLanguage, onChangeEnvironment }) => {
  const history = useHistory();
  const location = useLocation();
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const currentState = state.history[state.history.length - 1];
  const [confirmDelete, setConfirmDelete] = useState('');
  const currentWebsite = find(state.websites, (w) => w.id === state.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;

  const advertisements = groupBy(
    filter(currentState.advertisements, (adv) => !adv.disabled),
    'reference'
  );
  const languages = currentState.languages;

  const handleDelete = () => {
    advertisementDeleteHandler(dispatch, confirmDelete);
    setConfirmDelete('');
  };
  const onEditAdvertisement = (reference, lng) => {
    history.push(`${routes.ADVERTISEMENT_DETAIL}/${reference}`, {
      language: lng
    });
  };

  return (
    <div className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
      {confirmDelete !== '' && <ConfirmBox title='Delete advertisement' text='Are you sure you want to delete this advertisement?' type='DANGER' onCancel={() => setConfirmDelete('')} onConfirm={handleDelete} />}

      <div className='builder-flex builder-mb-5'>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Advertisements</div>
        <Link to={`${routes.ADVERTISEMENT_DETAIL}/${v4()}`} className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100'>
          <i className='fal fa-plus builder-mr-2' />
          Add new
        </Link>
      </div>

      <div className='builder-flex builder-flex-col builder-w-full'>
        <div className='builder-overflow-x-auto'>
          <div className='builder-py-2 builder-align-middle builder-inline-block builder-min-w-full'>
            <div className='builder-shadow builder-overflow-hidden builder-border-b builder-border-gray-200'>
              <table className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
                <thead className='builder-bg-gray-50'>
                  <tr>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '50%' }}>
                      Banner
                    </th>
                    {map(
                      filter(languages, (l) => l.available),
                      (language) => {
                        return (
                          <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '10%' }}>
                            {language.code}
                          </th>
                        );
                      }
                    )}
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '30%' }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                  {map(keys(advertisements), (reference, i) => {
                    const advertisementsByRef = advertisements[reference];
                    const canEdit = advertisementsByRef.length > 0 && !advertisementsByRef[0].isDraft && !isCurrentSiteProduction ? false : true; // Only edit drafts in draft environment, (production posts not editable in draft)

                    return (
                      <tr key='reference'>
                        <td style={{ width: '50%' }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: advertisementsByRef[0].data
                            }}
                          />
                        </td>
                        {map(
                          filter(languages, (l) => l.available),
                          (language) => {
                            const hasAdvertisementForLanguage = find(advertisementsByRef, (adv) => adv.culture === language.code) ? true : false;
                            return (
                              <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '10%' }}>
                                {hasAdvertisementForLanguage ? <i className='fal fa-check builder-ml-1' style={{ fontSize: 12 }} /> : <i className='fal fa-times builder-ml-1' style={{ fontSize: 12 }} />}
                              </td>
                            );
                          }
                        )}

                        <td className='builder-flex builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '30%' }}>
                          {canEdit && (
                            <React.Fragment>
                              {map(
                                filter(languages, (l) => l.available),
                                (language) => {
                                  return (
                                    <div
                                      key={language.code}
                                      className='builder-flex builder-items-center builder-cursor-pointer builder-border-r builder-border-gray-300 builder-mr-3 builder-pr-3'
                                      onClick={() => onEditAdvertisement(reference, language.code)}
                                    >
                                      <span className='builder-uppercase'>{language.code}</span> <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                                    </div>
                                  );
                                }
                              )}
                              <div className='builder-flex builder-items-center builder-cursor-pointer' onClick={() => setConfirmDelete(reference)}>
                                Delete <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 12 }} />
                              </div>
                            </React.Fragment>
                          )}
                          {!canEdit && (
                            <React.Fragment>
                              <span className='builder-font-bold'>No actions allowed in draft environment</span>
                            </React.Fragment>
                          )}
                          s
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
