import React from 'react';
import { find } from 'lodash';

export const getCustomBlockContent = (data, language) => {
  if (data[language]) {
    return data[language];
  }

  if (data['nl']) {
    return data['nl'];
  }

  return '';
};

const BlockComponentCustom = ({ block, context }) => {
  const customBlock = find(context.customBlocks, (b) => b.name === block.componentName);

  if (!customBlock) {
    return null;
  }
  return (
    <div id={`b${block.id.split('-')[0]}`} className='font-body' style={{ minHeight: 50 }}>
      <div
        dangerouslySetInnerHTML={{
          __html: getCustomBlockContent(customBlock.data, context.culture)
        }}
      />
    </div>
  );
};

export default BlockComponentCustom;
