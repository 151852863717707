import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DispatchContext, StateContext } from '../../App';
import { filter, groupBy, includes, map } from 'lodash';
import { modalOpenHandler } from '../data/actions';
import { MODAL_TYPES } from '../data/reducers';

const GroupMenu = ({ advertisement, onSelectGroup, onDeleteGroup }) => {
  const history = useHistory();
  const location = useLocation();
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const currentState = state.history[state.history.length - 1];
  const [confirmDelete, setConfirmDelete] = useState('');

  const groups = filter(currentState.advertisementGroups, (adv) => !adv.disabled);
  const languages = currentState.languages;

  const handleCreateGroup = () => {
    modalOpenHandler(dispatch, MODAL_TYPES.ADVERTISEMENT_GROUP_CREATE);
  };

  if (groups.length === 0) {
    return (
      <React.Fragment>
        <div className='builder-font-bold builder-mt-4'>No groups defined.</div>
        <div className='builder-mb-4'> An advertisement will only be visible on the website if it contains a group.</div>
        <div className='builder-underline builder-font-semibold builder-cursor-pointer' onClick={handleCreateGroup}>
          Create new group
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className='builder-mt-4'>
      {map(groups, (group) => {
        const selected = includes(advertisement.groups, group.id);
        return (
          <div className='builder-flex builder-items-center'>
            <input
              type='checkbox'
              defaultChecked={selected}
              onChange={(e) => (selected ? onDeleteGroup(group.id) : onSelectGroup(group.id))}
              className='builder-h-4 builder-w-4 builder-text-indigo-600 focus:builder-ring-indigo-500 builder-border builder-border-gray-300 builder-rounded-md'
            />
            <label className='builder-ml-2 builder-block builder-text-sm builder-text-gray-900 builder-text-md'>{group.name}</label>
          </div>
        );
      })}

      <div className='builder-underline builder-font-semibold builder-cursor-pointer builder-mt-4' onClick={handleCreateGroup}>
        Create new group
      </div>
    </div>
  );
};

export default GroupMenu;
