import React, { useContext, useState } from 'react';
import { filter, map, orderBy, values } from 'lodash';
import ConfirmBox from '../../components/common/ConfirmBox';
import { useHistory } from 'react-router-dom';
import { getTranslationForPage } from '../../utils';
import { modalOpenHandler, pageDeleteHandler, pageSelectHandler } from '../data/actions';
import { MODAL_TYPES } from '../data/reducers';
import { DispatchContext, StateContext } from '../../App';

const Overview = ({ context, currentLanguage, setCurrentLanguage, onChangeEnvironment }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const currentState = state.history[state.history.length - 1];
  const [confirmDelete, setConfirmDelete] = useState('');

  const history = useHistory();
  const pages = currentState.pages;
  const languages = currentState.languages;

  const tableRows = orderBy(
    map(values(pages), (page) => {
      return {
        value: getTranslationForPage(page, currentLanguage) === '' ? 'homepage' : getTranslationForPage(page, currentLanguage),
        key: page.id,
        type: page.type,
        priority: page.url === 'index' ? 1 : 0
      };
    }),
    ['priority', 'value'],
    ['desc', 'asc']
  );

  const onSelectPage = (item) => {
    history.push('');
    pageSelectHandler(dispatch, item.key);
  };

  const onEditPage = (item, languageCode) => {
    history.push('');
    setCurrentLanguage(languageCode);
    pageSelectHandler(dispatch, item.key);
  };

  const onDeletePage = (item) => {
    pageDeleteHandler(dispatch, item.key);
  };

  const onCreatePage = () => {
    modalOpenHandler(dispatch, MODAL_TYPES.PAGE_CREATE);
  };

  return (
    <div className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
      {confirmDelete !== '' && (
        <ConfirmBox
          title='Delete page'
          text='Are you sure you want to delete this page?'
          type='DANGER'
          onCancel={() => setConfirmDelete('')}
          onConfirm={() => {
            onDeletePage(confirmDelete);
            setConfirmDelete('');
          }}
        />
      )}

      <div className='builder-flex builder-mb-5'>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Pages</div>
        <div className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100' onClick={() => onCreatePage()}>
          <i className='fal fa-plus builder-mr-2' />
          Add new
        </div>
      </div>

      <div className='builder-flex builder-flex-col builder-w-full'>
        <div className='builder-overflow-x-auto'>
          <div className='builder-py-2 builder-align-middle builder-inline-block builder-min-w-full'>
            <div className='builder-shadow builder-overflow-hidden builder-border-b builder-border-gray-200'>
              <table className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
                <thead className='builder-bg-gray-50'>
                  <tr>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '40%' }}>
                      Page
                    </th>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '10%' }}>
                      Type
                    </th>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '30%' }}>
                      Edit
                    </th>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '20%' }}>
                      Move to trash
                    </th>
                  </tr>
                </thead>
                <tbody className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                  {map(orderBy(tableRows, ['priority'], ['desc']), (row) => {
                    return (
                      <tr key={row.key}>
                        <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black builder-cursor-pointer builder-font-bold' style={{ width: '40%' }} onClick={() => onSelectPage(row)}>
                          /{row.value}
                        </td>
                        <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black builder-cursor-pointer builder-font-bold' style={{ width: '10%' }}>
                          {row.type}
                        </td>
                        <td className='builder-flex builder-items-center builder-space-x-4 builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-sm' style={{ width: '30%' }}>
                          {map(
                            filter(languages, (l) => l.available),
                            (language) => {
                              return (
                                <div key={language.code} className='builder-flex builder-items-center builder-text-md builder-uppercase builder-text-black builder-cursor-pointer' onClick={() => onEditPage(row, language.code)}>
                                  {language.code} <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                              );
                            }
                          )}
                        </td>
                        <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '20%' }}>
                          <div className='builder-flex builder-items-center builder-cursor-pointer' onClick={() => setConfirmDelete(row)}>
                            Delete <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 12 }} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
