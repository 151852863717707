import { useContext, useState } from 'react';
import { useMutation } from 'graphql-hooks';
import { DispatchContext } from '../../App';
import { setSession, stateReset } from '../data/actions';
import { resetSessionToDefault } from '../data/session';

const SIGNOUT = `
    mutation Logout($application: Int!) {
        logout(application: $application)  {
           ok          
        }
    }`;

export function useSignOut() {
  const [signoutMutation] = useMutation(SIGNOUT);
  const dispatch = useContext(DispatchContext);

  const handleSignOut = () => {
    return signoutMutation({
      variables: {
        application: 0
      }
    }).then((result) => {
      setSession(dispatch, resetSessionToDefault());
      stateReset(dispatch);
    });
  };

  return {
    signOut: () => {
      return handleSignOut();
    }
  };
}
