import React, { useEffect, useState } from 'react';
import { FileUploadMutation } from '../../data/graphql';
import cogoToast from 'cogo-toast';
import { useMutation } from 'graphql-hooks';
import Modal from 'react-modal';
import FilePicker from './FilePicker';
import { getExtension, isImage, isVideo } from '../../../utils';
import ReactPlayer from 'react-player';
import { v4 } from 'uuid';
import EditorHeader from './components/EditorHeader';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import TextInput from './components/TextInput';

let fileSelector;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: { zIndex: 999 }
};

const FileUrlFieldEditor = ({ definition, value, onChange, type, className, breadcrumbs, onBack }) => {
  const [filesModalVisible, setFilesModalVisible] = useState(false);
  const [urlModalVisible, setUrlModalVisible] = useState(false);
  const [urlModelValue, setUrlModelValue] = useState('');
  const [mutation] = useMutation(FileUploadMutation);

  const showFileSelector = (e) => {
    e.preventDefault();

    fileSelector = document.createElement('input');
    fileSelector.setAttribute('id', v4());
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');

    fileSelector.addEventListener('change', (e) => {
      handleChangeImage(e);
    });

    fileSelector.setAttribute('accept', type === 'image' ? '.png,.jpg,.jpeg,.svg,.webp' : '.mp4,.mov,.m4v');
    fileSelector.click();
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0];

    mutation({ variables: { file: file } })
      .then((res) => {
        const { ok, url } = res.data.fileUpload;
        if (ok) {
          onChange(url);
          cogoToast.success('image uploaded!');
        } else {
          cogoToast.error('Error uploading image!');
        }
      })
      .catch((error) => {
        cogoToast.error('Error uploading image!');
        console.log(error);
      });
  };

  const handleSelectExisting = (file) => {
    setFilesModalVisible(false);
    onChange(file.url);
  };

  const handleDelete = () => {
    onChange('');
  };

  return (
    <React.Fragment>
      <EditorHeader
        breadcrumbs={breadcrumbs}
        onBack={onBack}
        onClick={onBack}
        description={definition.description}
        renderMenu={() => {
          return (
            <div className='builder-flex'>
              {value && value !== '' && <ButtonWithIndicator loading={false} onClick={handleDelete} icon='fas fa-trash-alt' text='Delete' colorClass='builder-text-red-500 builder-bg-white' borderClass='builder-border builder-border-bg-red-500' />}

              <ButtonWithIndicator loading={false} onClick={() => setFilesModalVisible(true)} icon='fas fa-images' text='Choose existing' colorClass='builder-bg-white builder-text-primary' borderClass='builder-border builder-border-bg-primary' />

              <ButtonWithIndicator loading={false} onClick={showFileSelector} icon='fal fa-plus' text='Add new' colorClass='builder-bg-white builder-text-primary' borderClass='builder-border builder-border-bg-primary' />

              <ButtonWithIndicator loading={false} onClick={() => setUrlModalVisible(true)} icon='fal fa-plus' text='Add new (from url)' colorClass='builder-bg-white builder-text-primary' borderClass='builder-border builder-border-bg-primary' />
            </div>
          );
        }}
      />

      <div className='builder-flex builder-justify-center builder-mt-4'>
        {(!value || value === '') && <span className='builder-font-bold builder-text-primary'>{type === 'image' ? 'Currently No image selected' : 'Currently No video selected'}</span>}

        {value && value !== '' && (
          <div className={`builder-flex-1 builder-p-4 builder-justify-center ${className}`} style={{ maxWidth: 266 }}>
            <div className='builder-flex builder-justify-center builder-items-center' style={{ maxWidth: 266, minHeight: 200, maxHeight: 200 }}>
              {value && value !== '' && type === 'image' && isImage(value) && (
                <div>
                  <div>{getExtension(value)}</div>
                  <img src={value} style={{ maxWidth: 266, maxHeight: 200 }} alt={value} />
                </div>
              )}

              {value && value !== '' && type === 'video' && isVideo(value) && (
                <div>
                  <div>{value}</div>
                  <ReactPlayer url={value} playing={false} width={266} height={200} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <Modal isOpen={filesModalVisible} onRequestClose={() => setFilesModalVisible(false)} style={customStyles} contentLabel='Example Modal'>
        <div className='builder-flex builder-justify-end builder-mb-4'>
          <div
            className='builder-cursor-pointer'
            onClick={() => {
              setFilesModalVisible(false);
            }}
          >
            <i className='fal fa-times builder-text-xl' />
          </div>
        </div>

        <div style={{ width: 700, minHeight: 400, maxHeight: 500 }}>
          <FilePicker onSelect={handleSelectExisting} type={type} />
        </div>
      </Modal>

      <Modal isOpen={urlModalVisible} onRequestClose={() => setUrlModalVisible(false)} style={customStyles} contentLabel='Url modal'>
        <div className='builder-flex builder-justify-end builder-mb-4'>
          <div
            className='builder-cursor-pointer'
            onClick={() => {
              setUrlModalVisible(false);
            }}
          >
            <i className='fal fa-times builder-text-xl' />
          </div>
        </div>

        <div style={{ width: 700, minHeight: 400, maxHeight: 500 }}>
          <TextInput placeholder='URL' className='builder-border builder-border-gray-300 builder-rounded-md' value={urlModelValue} onChanged={(value) => setUrlModelValue(value)} />

          <ButtonWithIndicator
            loading={false}
            onClick={() => {
              onChange(urlModelValue);
              setUrlModalVisible(false);
            }}
            className='builder-ml-0'
            text='OK'
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default FileUrlFieldEditor;
