import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'graphql-hooks';
import { DispatchContext, StateContext } from '../../App';
import { v4 } from 'uuid';
import { channel, userChannel } from '../../pusher';
import { sessionRestrictionModalViewSetHandler, sessionTakeOverMessageSetHandler } from '../data/actions';
import { SessionRestrictionModalViews } from './SessionRestrictionModal';

const ClientMessageTypes = {
  take_over_request: 'builder_session_manager_take_over_request',
  take_over_request_accepted: 'builder_session_manager_take_over_request_accepted',
  take_over_request_declined: 'builder_session_manager_take_over_request_declined',
  force_sign_out: 'builder_session_manager_force_sign_out'
};

const CLIENT_MESSAGE_MUTATION = `
    mutation ClientMessage($application: Int!, $id: UUID!, $type: String!, $destination: String, $payload:JSONString) {
        clientMessage(application: $application, id: $id, type: $type, destination: $destination, payload: $payload)  {
           ok          
        }
    }`;

export function useClientMessages() {
  const [clientMessageMutation] = useMutation(CLIENT_MESSAGE_MUTATION);
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);

  const initializeMessage = (id, type, destination = undefined, payload = undefined) => {
    return {
      id: id,
      application: 0,
      type: type,
      destination: destination,
      payload: JSON.stringify(payload)
    };
  };

  const handleClientMessage = (message) => {
    clientMessageMutation({
      variables: { ...message }
    }).then((result) => {});

    return message;
  };

  return {
    applicationChannel: channel,
    userChannel: userChannel,
    clientMessageTypes: ClientMessageTypes,
    sendTakeOverRequest: () => handleClientMessage(initializeMessage(v4(), ClientMessageTypes.take_over_request)),
    sendTakeOverRequestAccepted: () => handleClientMessage(initializeMessage(v4(), ClientMessageTypes.take_over_request_accepted, state.sessionTakeOverMessage.origin)),
    sendTakeOverRequestDeclined: (message) => handleClientMessage(initializeMessage(v4(), ClientMessageTypes.take_over_request_declined, state.sessionTakeOverMessage.origin, { message: message })),
    sendForceSignOut: () => {
      handleClientMessage(initializeMessage(v4(), ClientMessageTypes.force_sign_out));
      sessionRestrictionModalViewSetHandler(dispatch, SessionRestrictionModalViews.ForceSignOutWaiting);
    }
  };
}
