import React, { useState } from 'react';
import { find, orderBy, take } from 'lodash';
import { FilterTypes } from '@digitalexperience/dealer-website-core';
import GenericList from '../../../../../components/common/GenericList';

const EquipmentFilter = ({ filterData, filterValues, filterAdd, filterDelete }) => {
  const [showAll, setShowAll] = useState(false);
  const orderedEquipments = orderBy(filterData, ['text']);
  const equipmentsToShow = showAll ? orderedEquipments : take(orderedEquipments, 10);

  return (
    <div className='mt-5'>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Equipments</div>
      <GenericList
        placeHolder='All'
        items={equipmentsToShow}
        values={filterValues}
        handleFilterChanged={(data) => {
          const hasFilterForEquipment = find(filterValues, (i) => i === data.value);
          if (hasFilterForEquipment) {
            filterDelete(FilterTypes.equipments, data.value);
          } else {
            filterAdd(FilterTypes.equipments, data.value);
          }
        }}
      />

      {filterData.length > 10 && (
        <div className='paragraph-default text-primary cursor-pointer hover:text-secondary mt-2' onClick={() => setShowAll(!showAll)}>
          {showAll ? (
            <div className='flex items-center'>
              Show less equipment
              <i className='fal fa-arrow-up ml-2' />
            </div>
          ) : (
            <div className='flex items-center'>
              Show more equipment
              <i className='fal fa-arrow-down ml-2' />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EquipmentFilter;
