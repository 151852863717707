import React from 'react';
import { map } from 'lodash';

const Breadcrumbs = ({ items, onBack, onClick }) => {
  return (
    <div className='builder-flex builder-items-center builder-overflow-hidden' style={{ maxWidth: 500 }}>
      <i
        className='fal fa-arrow-left builder-mr-6 builder-text-lg builder-cursor-pointer'
        onClick={(e) => {
          e.stopPropagation();
          onBack();
        }}
      />

      {map(items, (item, i) => {
        return (
          <div className=' builder-overflow-hidden'>
            <span
              className={` ${i < items.length - 1 ? 'hover:builder-underline builder-cursor-pointer builder-text-md' : 'builder-font-bold builder-text-md '}`}
              onClick={() => {
                if (i < items.length - 1) {
                  onClick(i);
                }
              }}
            >
              {' '}
              {item}
            </span>
            {i < items.length - 1 && <span className='builder-ml-1 builder-mr-1'>/</span>}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
