import React from 'react';
import { filter, find, groupBy, head, includes, isEmpty, map, orderBy } from 'lodash';
import { FilterTypes } from '@digitalexperience/dealer-website-core';
import SelectBox from '../../../../../components/common/SelectBox';

const ModelsFilter = ({ filterData, filterValues, filterAdd, filterDelete }) => {
  const makeFilterValues = map(filterValues, (v) => (v.indexOf('|') > 0 ? v.split('|')[0] : v));
  const modelFilterValues = map(
    filter(filterValues, (v) => v.indexOf('|') > 0),
    (makeModel) => ({
      make: makeModel.split('|')[0],
      model: makeModel.split('|')[1]
    })
  );

  const availableMakesForModels = makeFilterValues.length > 0 ? filter(filterData, (m) => includes(makeFilterValues, m.key)) : filterData;

  let modelOptions = map(availableMakesForModels, (make) => {
    return {
      value: make.key,
      label: make.text,
      make: make,
      options: make.models
        ? orderBy(
            map(make.models, (model) => {
              return {
                value: model.key,
                label: model.text,
                make: make
              };
            }),
            ['label']
          )
        : []
    };
  });

  let modelLabel = '';
  const modelFiltersByMake = groupBy(modelFilterValues, 'make');
  if (!isEmpty(modelFiltersByMake)) {
    Object.keys(modelFiltersByMake).map((key) => {
      const make = find(filterData, (m) => m.key === key);
      let modelString = '';
      map(modelFiltersByMake[key], (m) => {
        const model = find(make.models, (model) => model.key === m.model);
        if (model) {
          modelString = modelString ? `${modelString}, ${model.text}` : `${model.text}`;
        }
      });
      modelLabel = modelLabel ? `${modelLabel}, ${make.text}: ${modelString}` : `${make.text}: ${modelString}`;
    });
  }

  const handleFilterChanged = (data) => {
    const makeKey = data.make.key;
    const modelKey = data.value;
    const value = `${makeKey}|${modelKey}`;
    if (includes(filterValues, value)) {
      filterDelete(FilterTypes.makeModel, value);
    } else {
      filterAdd(FilterTypes.makeModel, value);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Models</div>
      <SelectBox
        value={modelLabel ? { label: modelLabel } : ''}
        options={orderBy(modelOptions, ['label'])}
        closeMenuOnSelect={false}
        placeholder='All'
        customComponents={{
          Option: ({ innerProps, isDisabled, data }) => {
            const isSelected = find(modelFilterValues, (item) => item.make === data.make.key && item.model === data.value) !== undefined;

            return !isDisabled ? (
              <div {...innerProps}>
                <div className='builder-flex builder-flex-row builder-py-1 builder-cursor-pointer builder-ml-5' onClick={() => handleFilterChanged(data)}>
                  <div className='builder-flex builder-items-center'>
                    <div className='builder-flex builder-items-center builder-justify-center builder-rounded builder-border builder-border-primary builder-mr-2' style={{ height: 16, width: 16 }}>
                      {isSelected && <i className='far fa-check builder-font-bold' style={{ fontSize: 10, marginTop: 2 }} />}
                    </div>
                  </div>
                  <div className={`builder-flex builder-flex-row builder-justify-between builder-flex-1 builder-relative  ${isSelected ? 'builder-text-bold' : ''}`}>
                    <div className='builder-capitalize'>{data.label}</div>
                  </div>
                </div>
              </div>
            ) : null;
          }
        }}
        isLoading={false}
      />
    </React.Fragment>
  );
};

export default ModelsFilter;
