import React, { useEffect, useRef, useState } from 'react';

const IntegerInput = ({ value, placeHolder, onChanged }) => {
  const inputRef = useRef(null);
  const [intValue, setIntValue] = useState(value);

  useEffect(() => {
    setIntValue(value);
  }, [value]);

  return (
    <input
      className='builder-text-sm builder-shadow builder-appearance-none builder-border-b builder-border-gray-300 builder-w-full builder-py-2 builder-px-3 builder-text-gray-700 builder-leading-tight builder-focus:outline-none builder-focus:shadow-ring'
      type='number'
      ref={inputRef}
      placeholder={placeHolder}
      value={intValue}
      onChange={(e) => setIntValue(parseInt(e.target.value))}
      onBlur={() => {
        onChanged(intValue);
      }}
      onFocus={() => {
        inputRef.current.focus();
        inputRef.current.select();
      }}
    />
  );
};

export default IntegerInput;
