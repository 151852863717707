import React, { useEffect, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { forEach } from 'lodash';
import tailwind from 'dealer-website-components/dist/css/tailwind.config';

const getColorRecursive = (item, result) => {
  const keys = Object.keys(item);
  forEach(keys, (key) => {
    const value = item[key];

    if (typeof value === 'object') {
      getColorRecursive(value, result);
    } else {
      result.push(value);
    }
  });
};

const DraftJsEditor = ({ value, onChange, className, readOnly, updateValueOnChange }) => {
  const rawData = value && value !== '' ? convertFromRaw(value) : undefined;
  const [editorState, setEditorState] = React.useState(rawData ? EditorState.createWithContent(rawData) : EditorState.createEmpty());
  const editorRef = useRef(null);

  useEffect(() => {
    if (updateValueOnChange) {
      // ugly, but time issues
      const rawData = value && value !== '' ? convertFromRaw(value) : undefined;
      setEditorState(rawData ? EditorState.createWithContent(rawData) : EditorState.createEmpty());
    }
  }, [value]);

  const handleChange = () => {
    const raw = convertToRaw(editorState.getCurrentContent());
    const compareA = JSON.stringify(raw);
    const compareB = JSON.stringify(value);

    if (compareA !== compareB) {
      onChange(raw);
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const getThemeFonts = () => {
    const fonts = [];
    if (tailwind) {
      const keys = tailwind.theme.fontFamily ? Object.keys(tailwind.theme.fontFamily) : [];
      forEach(keys, (key) => {
        const fontFamilies = tailwind.theme.fontFamily[key];
        forEach(fontFamilies, (font) => {
          fonts.push(font);
        });
      });
    }
    return fonts;
  };

  const getThemeFontSizes = () => {
    const sizes = [];
    if (tailwind) {
      const keys = tailwind.theme.extend.fontSize ? Object.keys(tailwind.theme.extend.fontSize) : [];
      forEach(keys, (key) => {
        try {
          const size = parseInt(key);
          if (Number.isInteger(size)) {
            sizes.push(size);
          }
        } catch (ex) {}
      });
    }
    return sizes;
  };

  const getThemeColors = () => {
    const colors = ['#FFFFFF', '#000000'];
    if (tailwind) {
      getColorRecursive(tailwind.theme.extend.colors, colors);
    }
    return colors;
  };

  return (
    <div className={className} style={{ minHeight: 400 }}>
      <Editor
        editorState={editorState}
        ref={editorRef}
        editorStyle={{
          minHeight: 400,
          cursor: readOnly ? 'not-allowed' : 'text',
          padding: '5px 10px'
        }}
        onEditorStateChange={onEditorStateChange}
        readOnly={readOnly}
        onChange={handleChange}
        toolbar={{
          options: ['inline', 'link', 'list', 'blockType', 'fontFamily', 'fontSize', 'colorPicker', 'textAlign', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
            bold: { className: 'test', style: { color: 'red' } }
          },
          list: {
            options: ['unordered', 'ordered']
          },
          blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
          },
          fontSize: {
            // options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 40, 48, 60, 72, 96],
            options: getThemeFontSizes()
          },
          colorPicker: {
            // colors: ['#FFFFFF', '#000000'],
            colors: getThemeColors()
          },
          fontFamily: {
            options: getThemeFonts()
          }
        }}
        stripPastedStyles={true}
      />
    </div>
  );
};

DraftJsEditor.defaultProps = {
  className: 'builder-border builder-border-gray-300 bg-gray-100',
  readOnly: false,
  updateValueOnChange: false
};

export default DraftJsEditor;
