import React from 'react';
import { FilterTypes } from '@digitalexperience/dealer-website-core';

const SearchFilter = ({ filterValues, filterAdd, filterUpdate }) => {
  const value = filterValues && filterValues.length > 0 ? filterValues[0] : '';

  const handleChange = (value) => {
    if (filterValues.length === 0) {
      filterAdd(FilterTypes.search, value);
    } else {
      filterUpdate(FilterTypes.search, value);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Search</div>
      <div className='flex w-full mb-4 relative bg-white items-center rounded border border-primary font-secondary text-primary rounded-l-10 rounded-r-10'>
        <input type='text' className='flex-1 font-secondary text-primary outline-none rounded-l-10' style={{ padding: '15px 18px 15px 15px' }} placeholder='search text' value={value} onChange={(e) => handleChange(e.target.value)} />
      </div>
    </React.Fragment>
  );
};

export default SearchFilter;
