import React from 'react';

const ButtonWithIndicator = ({ colorClass, borderClass, className, text, icon, loading, onClick, disabled }) => {
  return (
    <div className={`builder-px-4 builder-py-2 ${colorClass} ${borderClass} ${disabled ? 'opacity-25' : 'builder-cursor-pointer opacity-100'} ${className}`} onClick={disabled ? undefined : onClick}>
      {loading && (
        <div className={`lds-ring mr-2 ${colorClass !== 'builder-bg-white' ? 'lds-ring-white' : 'lds-ring-black'}`} style={{ width: 14, borderColor: 'white' }}>
          <div></div>
        </div>
      )}

      {!loading && icon && <i className={`${icon} builder-mr-2`} />}

      {text}
    </div>
  );
};

ButtonWithIndicator.defaultProps = {
  colorClass: 'builder-bg-white',
  borderClass: 'builder-border builder-border-gray-300',
  disabled: false,
  className: 'builder-ml-2'
};

export default ButtonWithIndicator;
