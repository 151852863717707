import React, { forwardRef, useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import TextInput from '../builder/editors/components/TextInput';
import ButtonWithIndicator from '../../components/common/ButtonWithIndicator';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { DispatchContext, StateContext } from '../../App';
import { campaignAddHandler, campaignEditHandler } from '../data/actions';
import { filter, find, some } from 'lodash';
import moment from 'moment';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '55%',
    overflow: 'visible'
  },
  overlay: {
    zIndex: 999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const initialState = {
  name: '',
  start: new Date(),
  stop: new Date()
};

const CampaignCreateModal = ({ isOpen, onClose, context, campaign }) => {
  const [state, setState] = useState(campaign ? campaign : initialState);
  const [formErrors, setFormErrors] = useState({});
  const globalState = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const currentState = globalState.history[globalState.history.length - 1];
  const currentWebsite = find(globalState.websites, (w) => w.id === globalState.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;

  const availableCampaigns = () => {
    if (isCurrentSiteProduction) {
      return filter(currentState.campaigns, (c) => !c.disabled && !c.isDraft);
    } else {
      return filter(currentState.campaigns, (c) => !c.disabled && c.isDraft);
    }
  };

  const setValue = (key, value) => {
    setState({
      ...state,
      [key]: value
    });
  };

  useEffect(() => {
    if (isOpen) {
      const mappedState = campaign
        ? {
            ...campaign,
            start: new Date(campaign.start),
            stop: new Date(campaign.stop)
          }
        : initialState;
      setState(mappedState);
      setFormErrors({});
    }
  }, [isOpen]);

  const formatToYearMonthDay = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (state.name === '' || state.name.length < 4) {
      errors['name'] = 'The campaign name needs to be at least 4 chars long.';
      isValid = false;
    }

    if (new Date(state.start) > new Date(state.stop)) {
      errors['date'] = 'The stop date needs to be greater than start date.';
      isValid = false;
    }

    const startExists = some(availableCampaigns(), (c) => formatToYearMonthDay(state.start) >= formatToYearMonthDay(c.start) && formatToYearMonthDay(state.start) <= formatToYearMonthDay(c.stop));
    const stopExists = some(availableCampaigns(), (c) => formatToYearMonthDay(state.stop) >= formatToYearMonthDay(c.start) && formatToYearMonthDay(state.stop) <= formatToYearMonthDay(c.stop));

    if (startExists || stopExists) {
      let msg = '';
      if (startExists && stopExists) {
        msg = 'The chosen dates match with one or more other campaigns.';
      } else if (startExists && !stopExists) {
        msg = 'The start date matches with one or more other campaigns.';
      } else if (!startExists && stopExists) {
        msg = 'The stop date matches with one or more other campaigns.';
      }
      errors['date'] = msg;
      isValid = false;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const handleCreateCampaign = () => {
    if (isValid()) {
      if (campaign) {
        campaignEditHandler(dispatch, state.id, state.name, state.start, state.stop);
      } else {
        campaignAddHandler(dispatch, state.name, state.start, state.stop, !isCurrentSiteProduction);
      }

      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} style={customStyles} appElement={document.getElementById('root') || undefined} contentLabel='New campaign modal'>
      <div className='builder-flex builder-w-full builder-justify-end builder-mb-5'>
        <i className='fal fa-times builder-text-xl builder-cursor-pointer builder-text-2xl' onClick={() => onClose()} />
      </div>

      <div className='builder-text-black builder-font-semibold builder-mb-2.5'>Create new page from template</div>

      <div className='builder-w-full'>
        <div className='builder-flex-col'>
          <TextInput
            placeHolder='Campaign name'
            className={`builder-flex builder-flex-1 builder-border builder-w-full builder-shadow-sm builder-rounded-md ${formErrors['name'] ? 'builder-border-red-300' : 'builder-border-gray-300'}`}
            value={state.name}
            onChanged={(value) => setValue('name', value)}
          />
          {formErrors['name'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['name']}</span>}
        </div>

        <div className='builder-flex-col builder-mt-6 '>
          <div className='builder-flex builder-space-x-10'>
            <div className=' '>
              <div className='builder-text-black builder-font-semibold builder-mb-2.5'>Start date</div>
              <DatePicker
                selected={state.start}
                onChange={(date) => setValue('start', date)}
                className={`${formErrors['date'] ? 'builder-border-red-300' : 'builder-border-gray-300'}`}
                customInput={<CustomInput />}
                dateFormat='dd/MM/yyyy'
                minDate={new Date()}
              />
            </div>

            <div className=''>
              <div className='builder-text-black builder-font-semibold builder-mb-2.5'>Stop date</div>
              <DatePicker
                selected={state.stop}
                onChange={(date) => setValue('stop', date)}
                className={`${formErrors['date'] ? 'builder-border-red-300' : 'builder-border-gray-300'}`}
                customInput={<CustomInput />}
                dateFormat='dd/MM/yyyy'
                minDate={new Date()}
              />
            </div>
          </div>
          {formErrors['date'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['date']}</span>}
        </div>
      </div>

      <div className='builder-flex builder-justify-start builder-mt-6'>
        <ButtonWithIndicator loading={false} onClick={() => onClose()} className='builder-ml-0' text='Cancel' />

        <ButtonWithIndicator loading={false} onClick={() => handleCreateCampaign()} text='Save' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
      </div>
    </Modal>
  );
};

const CustomInput = forwardRef(({ value, onClick, className, onChange }, ref) => (
  <div className={`builder-border builder-px-3 builder-py-1.5 builder-shadow-sm builder-rounded-md builder-cursor-pointer ${className}`} onClick={onClick} ref={ref}>
    <i aria-hidden='true' className='fal fa-calendar-alt builder-mr-2' />
    <input value={value} type='text' onChange={onChange} className='focus:builder-outline-none builder-cursor-pointer builder-text-gray-700' />
  </div>
));

export default CampaignCreateModal;
