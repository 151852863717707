import React, { useContext, useEffect, useState } from 'react';
import { filter, find, map } from 'lodash';
import TextInput from '../builder/editors/components/TextInput';
import WysiwygEditor from '../../components/common/WysiwygEditor';
import { DispatchContext, StateContext } from '../../App';
import { useLocation, useParams } from 'react-router-dom';
import { advertisementEditHandler, advertisementInitHandler } from '../data/actions';
import { v4 } from 'uuid';
import GroupMenu from './GroupMenu';

const INITIAL_ADVERTISEMENT_DATA = {
  id: v4(),
  description: '',
  data: '',
  groups: [],
  culture: 'nl',
  disabled: false,
  isDraft: false
};

const Detail = ({ context }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const currentState = state.history[state.history.length - 1];
  const currentWebsite = find(state.websites, (w) => w.id === state.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;
  const { reference } = useParams();
  const location = useLocation();
  const [advertisementLanguage, setAdvertisementLanguage] = useState(location?.state?.language ? location.state.language : context.culture);
  const advertisement = find(currentState.advertisements, (adv) => adv.reference === reference && adv.culture === advertisementLanguage);
  const [isGroupOpen, setIsGroupOpen] = useState(true);
  const [editorRef, setEditorRef] = useState();
  const [editorFocused, setEditorFocused] = useState(false);

  useEffect(() => {
    if (advertisementLanguage) {
      advertisementInitHandler(dispatch, {
        ...INITIAL_ADVERTISEMENT_DATA,
        id: v4(),
        reference: reference,
        culture: advertisementLanguage,
        isDraft: !isCurrentSiteProduction
      });
    }
  }, [advertisementLanguage, reference]);

  const handleChangeValue = (key, value) => {
    const data = { ...advertisement, [key]: value };
    advertisementEditHandler(dispatch, data);
  };

  if (!advertisement || !currentState) {
    return null;
  }

  return (
    <div className='builder-flex builder-p-5 builder-w-full h-full builder-bg-gray-50'>
      <div className='builder-w-3/4 builder-mr-5'>
        <div className='builder-flex builder-flex-col builder-mb-5'>
          <div className='builder-flex builder-flex-col builder-w-200 builder-mb-5 builder-bg-white builder-p-5 builder-shadow-md'>
            <div className='builder-flex builder-mb-4'>
              <span className='builder-text-md builder-text-black builder-font-bold'>Language:</span>
              <span className='builder-uppercase builder-text-black builder-font-bold builder-ml-2 text-builder-md'>{advertisementLanguage}</span>
            </div>
            <div className='builder-flex'>
              <span className='builder-text-md builder-text-black builder-font-bold'>Translations: </span>
              <div className='builder-flex builder-space-x-2 builder-ml-2'>
                {map(
                  filter(currentState.languages, (l) => l.code !== advertisementLanguage && l.available),
                  (language) => {
                    return (
                      <div key={language.code} className='builder-flex builder-items-center builder-uppercase builder-cursor-pointer text-builder-md' onClick={() => setAdvertisementLanguage(language.code)}>
                        {language.code} <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>

          <div className='builder-flex builder-mb-5 builder-bg-white'>
            <TextInput
              placeHolder='Add description'
              className='builder-flex builder-flex-1 builder-border builder-gray-300 builder-w-full builder-rounded-md'
              value={advertisement.description}
              onChanged={(value) => handleChangeValue('description', value)}
            />
          </div>
          <WysiwygEditor
            data={advertisement.data || ''}
            onChange={(newvalue) => {
              handleChangeValue('data', newvalue);
            }}
            onFocus={() => setEditorFocused(true)}
            onBlur={() => setEditorFocused(false)}
            getRef={(ref) => setEditorRef(ref)}
          />
        </div>
      </div>

      <div className='builder-w-1/4'>
        <div className={`builder-pt-2 builder-pb-4 builder-bg-white builder-mb-5 ${isGroupOpen ? 'builder-border-b builder-border-gray-300 builder-shadow-sm' : 'builder-mb-2'}`}>
          <div className='builder-flex builder-justify-between builder-items-center builder-cursor-pointer' onClick={() => setIsGroupOpen(!isImageOpen)}>
            <h3 className='builder-font-bold builder-text-lg builder-ml-5'>Groups</h3>
            <i className={`fas builder-mr-5 builder-text-md ${isGroupOpen ? 'fa-caret-up' : 'fa-caret-down'}`} />
          </div>
          <div className={`builder-px-5 ${isGroupOpen ? 'builder-flex builder-flex-col ' : 'builder-hidden'}`}>
            <GroupMenu
              advertisement={advertisement}
              onSelectGroup={(groupId) => {
                handleChangeValue('groups', [...advertisement.groups, groupId]);
              }}
              onDeleteGroup={(groupId) => {
                handleChangeValue(
                  'groups',
                  filter(advertisement.groups, (g) => g !== groupId)
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
